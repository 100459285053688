import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Library } from '@googlemaps/js-api-loader';
import { GoogleMap, Marker } from "@react-google-maps/api";
import React, { RefObject } from "react";


// Customizable Area Start

interface AddressDetails {
  streetNumber: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
}

interface LatLng {
  lat: number;
  lng: number;
}

export interface Data{
  hotel_name: string;
  star_rated: boolean;
  hotel_rating: number | null;
  email_address: string;
  phone_number: string;
  alt_phone_number: string;
  fax_number: string;
}

export interface UserDataArrayType {
  id: number,
  name: string,
  selected: boolean
}
export interface Errors {
  hotel_name: string;
  hotel_rating: string;
  email_address: string;
  phone_number: string;
  alt_phone_number: string;
  fax_number: string;
  streetNumber: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
}
export interface Option {
  label: string;
  value: string;
}

interface AutocompleteComponent {
  getPlace: () => google.maps.places.PlaceResult;
}

interface ImageWithRotation {
  file: File;
  rotation: number;
}

interface IsEditing {
  [id: number]: boolean;
}
const defaultCenter = {
  lat: 51.5074,
  lng: -0.1278,
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
navigation: any;
id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeStep: number;
    data : Data;
    errors: Errors;
    showAddDestinationBox: boolean;
    newDestination: string;
    files: ImageWithRotation[];
    isDragging: boolean;
    anchorEl: HTMLElement | null;
    selectedFileIndex: number | null;
    isEditing: IsEditing;
    center: LatLng;
    markerPosition: LatLng;
    autocomplete: google.maps.places.Autocomplete | null;
    isLoaded: boolean;
    libraries: Library[];
    mapInstance: any;
    addressDetails: AddressDetails;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class HotelCatalogueController extends BlockComponent<
Props,
  S,
  SS
  > {
    // Customizable Area Start
    postHotelCatalogueApiId = ""
    public autocompleteRef: RefObject<AutocompleteComponent> = React.createRef();
    public mapRef: RefObject<GoogleMap> = React.createRef();
    public markerRef: RefObject<Marker> = React.createRef();

    // Customizable Area End

    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);

      this.subScribedMessages = [
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
      ];

      this.state = {
        // Customizable Area Start
        activeStep: 0,
        mapInstance: null,
        libraries : ['places'],
        isEditing: {
          1: false,
          2: false,
          3: false,
          4: false,
          5: false,
          6: false,
          7: false,
          8: false,
        },
        anchorEl: null,
        selectedFileIndex: null,
        files: [],
        isDragging: false,
        data: {
          hotel_name: "",
          star_rated: false,
          hotel_rating: null,
          email_address: "",
          phone_number: "",
          alt_phone_number: "",
          fax_number: "",
        },
        addressDetails: {
          streetNumber: '',
          street: '',
          postalCode: '',
          city: '',
          country: '',
        },
        showAddDestinationBox: false,
        newDestination: "",
        errors: {
          hotel_name: "",
          hotel_rating: "",
          email_address: "",
          phone_number: "",
          alt_phone_number: "",
          fax_number: "",    
          streetNumber: "",
          street: "",
          postalCode: "",
          city: "",
          country: "",   
        },
        center: defaultCenter,
        markerPosition: defaultCenter,
        
        autocomplete: null,
        isLoaded: false,
       // Customizable Area End
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

      // Customizable Area Start
      // Customizable Area End
    }

    async receive(from: string, message: Message) {
      // Customizable Area Start
      
      // Customizable Area End
    }

  
    // Customizable Area Start

    triggerMapResize = () => {
      if (this.state.mapInstance) {
        google.maps.event.trigger(this.state.mapInstance, 'resize');
      }
    };
    
    // Call this method when transitioning to page 3 (after the map becomes visible)
    componentDidUpdate() {
      if (this.state.activeStep === 3 && this.state.activeStep !== 3) {
        this.triggerMapResize();
      }
    }

    handlePlaceSelect = () => {
      const autocomplete = this.state.autocomplete;
      if (autocomplete) {
        const place = autocomplete.getPlace();
  
        if (place?.geometry?.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
  
          this.setState({ markerPosition: { lat, lng }, center: { lat, lng } });
  
          const address = this.extractAddressComponents(
            place.address_components || []
          );
          this.setState({ addressDetails: address });
        }
      }
    };
  
    geocodeLatLng(lat: number, lng: number) {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat, lng };
  
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results && results.length > 0) {
            const address = this.extractAddressComponents(
              results[0].address_components
            );
            this.setState({ addressDetails: address });
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      });
    }
  

    
    extractAddressComponents = (
      components: google.maps.GeocoderAddressComponent[]
    ): AddressDetails => {
      let address: AddressDetails = {
        streetNumber: "",
        street: "",
        city: "",
        postalCode: "",
        country: "",
      };
  
      components.forEach((component) => {
        const types = component.types;
  
        if (types.includes("street_number")) {
          address.streetNumber = component.long_name;
        }
  
        if (types.includes("route")) {
          address.street = component.long_name;
        }
  
        if (types.includes("locality")) {
          address.city = component.long_name;
        }
  
        if (types.includes("postal_code")) {
          address.postalCode = component.long_name;
        }
  
        if (types.includes("country")) {
          address.country = component.long_name;
        }
      });
  
      return address;
    };

    getValidationRules = (): { [key in keyof S['data']]?: (value: any) => string } => ({
      hotel_name: (value) =>
        value.length < 3 || value.length > 50
          ? 'Hotel name must be between 3 and 50 characters long'
          : '',

      email_address: (value) =>
        !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)
          ? 'Enter a valid email address'
          : '',

      phone_number: (value) =>
        value.length !== 10
          ? 'Enter a valid 10-digit phone number'
          : '',

      alt_phone_number: (value) =>
        value.length !== 10
          ? 'Enter a valid 10-digit phone number'
          : '',

      fax_number: (value) =>
        value.length !== 10
          ? 'Enter a valid 10-digit fax number'
          : '',
    });

    onValueChange = (name: keyof S['data'], value: any) => {
      const errors = { ...this.state.errors };

      const isNumericField = (name === 'phone_number' || name === 'alt_phone_number' || name === 'fax_number');
      
      if (isNumericField && !/^\d*$/.test(value)) {
        return; 
      }

      const validationRules = this.getValidationRules();
      
      const validateField = (name: keyof S['data'], value: any) => {
        const validate = validationRules[name];
        return validate ? validate(value) : '';
      };

      if (name in errors) {
        errors[name as keyof Errors] = validateField(name, value);
      }

      this.setState({
        data: { ...this.state.data, [name]: value },
        errors,
      });
    };

   

    handleBackClick = () => {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep - 1,
      }));
    };
  
    handleNextClick = () => {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
  
  };

    // Customizable Area End
}
