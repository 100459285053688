import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../../packages/framework/src/BlockComponent";
import { FormikValues } from "formik";

type BusinessField =
    "Business profile" | "Legal" | "User management" | null;

type BusinessSettingsFieldsType =
    "General Business Information" | "Payment and Banking Information" | "About Us Information" | null

type ProfileField =
    "Account settings" | "Notification" | "Language" | null

type LegalSettingsFieldType =
    "Terms and Conditions" | "Payment Policy" | "Cancellation Policy" | "Location Instructions"

interface BankingResponseJson {
    data: {
        data: {
            type: string;
            id: string;
            attributes: {
                bank_name: string;
                account_number: string;
                account_holder_name: string;
                IBAN: string;
                is_verified: boolean;
                sort_code: string;
                swift: string;
                routing_number: string;
            };
        };
    };
    message: string;
}

interface FormikBankingValues {
    bank_name: string;
    account_number: string;
    account_holder_name: string;
    IBAN: string;
    is_verified: boolean;
    sort_code: string;
    swift: string;
    routing_number: string;
}


interface UserArray {
    fullName: string;
    userRole: string;
    jobTitle: string;
    email: string;
    phoneNumber: string;
    username: string;
    password: string;
    accessPermission: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    businessActiveField: BusinessField
    BusinessSettings: boolean
    ProfileSettings: boolean
    profileActiveField: ProfileField
    businessProfile: boolean
    BusinessSettingsFields: BusinessSettingsFieldsType
    bankingFormikValues: FormikBankingValues
    aboutUsInfo: string
    termsAndConditions: string
    LegalSettingsField: LegalSettingsFieldType
    cancellationPolicy: string
    locationInstruction: string
    paymentPolicy: string
    addUserDialogBox: boolean
    userListArray: UserArray[]
    selectedUser: UserArray | null
    selectedUserIndex: number | null
    anchorEl: HTMLElement | null
    language: string
    allowPushNotificationDevice: boolean
    allowPushNotification: boolean
    passwordDialogBox: boolean
    allowEmail: boolean
    email: string
    emailDialogBox: boolean
    showNewPassword: boolean,
    showOldPassword: boolean,
    old_password: string,
    showConfirmPassword: boolean,
    confirm_password: string,
    new_password: string,
    showOtpSection: boolean,
    showUpdateEmailDialogBox: boolean
    firstName: string;
    otp: string,
    confirmEmail: string
    lastName: string
    newEmail: string
    otpToken: string
    password: string
    showSuccessDialog: boolean

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ActivitySettingsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            BusinessSettingsFields: 'General Business Information',
            BusinessSettings: true,
            businessActiveField: 'Business profile',
            profileActiveField: null,
            ProfileSettings: false,
            businessProfile: true,
            aboutUsInfo: "",
            bankingFormikValues: {
                account_holder_name: "",
                bank_name: "",
                is_verified: false,
                account_number: "",
                swift: "",
                IBAN: "",
                routing_number: "",
                sort_code: "",
            },
            cancellationPolicy: "",
            LegalSettingsField: 'Terms and Conditions',
            paymentPolicy: "",
            locationInstruction: "",
            termsAndConditions: "",
            addUserDialogBox: false,
            userListArray: [],
            selectedUser: null,
            anchorEl: null,
            selectedUserIndex: null,
            language: "",
            allowPushNotification: false,
            allowPushNotificationDevice: false,
            allowEmail: true,
            emailDialogBox: false,
            passwordDialogBox: false,
            email: "yop@mail.com",
            showConfirmPassword: false,
            showOldPassword: false,
            old_password: "",
            showNewPassword: false,
            confirm_password: "",
            new_password: "",
            showUpdateEmailDialogBox: false,
            showOtpSection: false,
            firstName: "",
            otp: "",
            newEmail: "",
            lastName: "",
            confirmEmail: "",
            otpToken: "",
            password: "",
            showSuccessDialog: false



            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    handleBusinessFieldClick = (field: BusinessField) => {
        this.setState({
            businessActiveField: field,
            BusinessSettings: true,
            profileActiveField: null,
            ProfileSettings: false,
        })
    }

    handleProfileFieldClick = (field: ProfileField) => {
        this.setState({
            businessActiveField: null,
            BusinessSettings: false,
            profileActiveField: field,
            ProfileSettings: true,
        })
    }

    handleBusinessTabChange = (event: React.ChangeEvent<{}>, newValue: BusinessSettingsFieldsType) => {
        this.setState({
            BusinessSettingsFields: newValue
        });
    };

    handleLegalTabChange = (event: React.ChangeEvent<{}>, newValue: LegalSettingsFieldType) => {
        this.setState({
            LegalSettingsField: newValue
        });
    };

    handleGeneralBusinessFormikValues = () => { }

    handleAboutUsInfo = async () => { }

    handleBankingFormikValues = () => { }

    handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            aboutUsInfo: e.target.value
        })
    }

    handlePaymentPolicyInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            paymentPolicy: e.target.value
        })
    }

    handleTermsAndConditionsInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            termsAndConditions: e.target.value
        })
    }


    handleLocationInstructionInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            locationInstruction: e.target.value
        })
    }

    handleCancellationPolicyInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            cancellationPolicy: e.target.value
        })
    }


    handlePolicies = () => { }


    handleFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ firstName: event.target.value })
    }

    handleLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ lastName: event.target.value })
    }

    handleAccountSettings = () => { }


    openEmailDialogBox = () => {
        this.setState({ emailDialogBox: true })
    }

    handleEmailChange = (values: FormikValues) => {
        this.setState({
            showUpdateEmailDialogBox: true,
            newEmail: values.email,
            confirmEmail: values.confirmEmail,
            password: values.passwoed
        })
        this.closeEmailDialogBox()
    }

    handleEmailDialogContinue = ()=>{
        this.setState({ showOtpSection: true })
        this.closeUpdateEmailDialogBox()
    }

    closeUpdateEmailDialogBox = () => {
        this.setState({ showUpdateEmailDialogBox: false })
    }

    closeEmailDialogBox = () => {
        this.setState({ emailDialogBox: false })
    }

    openPasswordDialogBox = () => {
        this.setState({ passwordDialogBox: true })
    }

    closePasswordDialogBox = () => {
        this.setState({ passwordDialogBox: false })
    }

    handleToggle = (value: number) => {
        if (value === 1) {
            this.setState((prev) => ({ showOldPassword: !prev.showOldPassword, }))
        } else if (value === 2) {
            this.setState((prev) => ({ showNewPassword: !prev.showNewPassword }))
        }
        else if (value === 3) {
            this.setState((prev) => ({ showConfirmPassword: !prev.showConfirmPassword }))
        }
    }


    handlePasswordChange = (values: { old_password: string, new_password: string, confirm_password: string }, { resetForm }: any) => {
        this.setState({
            old_password: values.old_password,
            new_password: values.new_password,
            confirm_password: values.confirm_password
        },
        )
        resetForm()
        this.closePasswordDialogBox()
    }


    handleDeleteAccount = () => {

    }

    handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ 
            language: event.target.value })
    }

    handleAllowPushNotificationDevice = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ 
            allowPushNotificationDevice: event.target.checked })
    }

    handleAllowPushNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ 
            allowPushNotification: event.target.checked })
    }

    handleAllowEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ 
            allowEmail: event.target.checked })
    }

    handleSettings = () => { }

    handleAddUserDialog = () => {
        this.setState({
            addUserDialogBox: true,
            selectedUser: null,
            selectedUserIndex: null,
        });
    };

    closeAddUserDialog = () => {
        this.setState({
            addUserDialogBox: false
        })
    }


    handleSaveUser = (values: UserArray) => {
        const { selectedUserIndex, userListArray } = this.state;

        if (selectedUserIndex !== null) {
            const updatedUserList = [...userListArray];
            updatedUserList[selectedUserIndex] = values;
            this.setState({
                userListArray: updatedUserList,
                selectedUserIndex: null,
                selectedUser: null,
            });
        } else {
            this.setState((prevState) => ({
                userListArray: [...prevState.userListArray, values],
            }));
        }
        this.closeAddUserDialog();
    };

    handleEditUser = () => {
        const { selectedUserIndex } = this.state;
        if (selectedUserIndex !== null) {
            this.setState({
                addUserDialogBox: true,
            });
        }
        this.handleMenuClose();
    };

    handleDeleteUser = () => {
        const { selectedUserIndex, userListArray } = this.state;
        const filteredUsers = userListArray.filter((_, i) => i !== selectedUserIndex);
        this.setState({
            userListArray: filteredUsers,
            selectedUserIndex: null,
            selectedUser: null,
            addUserDialogBox: false,
        });
        this.handleMenuClose();
    };

    handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        this.setState({
            anchorEl: event.currentTarget,
            selectedUser: this.state.userListArray[index],
            selectedUserIndex: index
        });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };


    // Customizable Area End
}
