import React from "react";
// ...

// Customizable Area Start
import { Box, Button,Checkbox,CheckboxProps,InputAdornment,MobileStepper, TextField, TextFieldProps, Typography, withStyles } from "@material-ui/core";
import {
  GoogleMap,
  Autocomplete,
  LoadScriptNext,
  MarkerF,
} from '@react-google-maps/api';
const CssTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
      },
      '&:hover fieldset': {
        borderColor: '#CBD5E1',
      },
      '&.Mui-focused fieldset': {
        borderColor: (props: TextFieldProps & { error?: boolean }) =>
          props.error ? '#DC2626' : '#625d58',
      },
    },
  },
}))(TextField);

const CssTextField2 = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FAA81D',
      },
    },
  },
})(TextField);


// Customizable Area End

import HotelCatalogueController, {
  Errors,
  Props,
} from "./HotelCatalogueController";
import {AddCircleOutlineOutlined, StarBorderOutlined, PlaceOutlined} from "@material-ui/icons";
import Rating from "@material-ui/lab/Rating";


export default class HotelCatalogue extends HotelCatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  logoSvg = require("../assets/logoSvg.svg");
  hotel_bgImage = require("../assets/hotel_bgImage.svg");
  addCarBtn = require("../assets/addCarBtn.svg");
  infoSvg = require("../assets/infoSvg.svg");
  bgImage = require("../assets/bgImage.svg");
  euroSvg = require("../assets/euroSvg.svg");
  addIconSvg = require("../assets/addIconSvg.svg");
  imageadd = require("../assets/imageadd.svg");
  divider = require("../assets/divider.svg");
  add = require("../assets/add.svg");
  editBtn = require("../assets/editBtn.svg");
  makeCover = require("../assets/makeCover.svg");
  rotateImg = require("../assets/rotate.svg");
  deleteImg = require("../assets/deleteImg.svg");
  verification = require("../assets/verification.svg");
  checkbox = require("../assets/checkbox_.svg");
  checkbox_fill = require("../assets/checkbox_fill.svg");
  current_location = require("../assets/locationBtn.svg");
  location_pin = require("../assets/location_pin.svg");
  
  renderStepper() {
    return (
      <MobileStepper
        variant="progress"
        steps={4}
        position="static"
        activeStep={1}
        nextButton={null}
        backButton={null}
      />
    );
  }

  renderErrorMessage = (fieldName: keyof Errors) => {
    const errorMessage = this.state.errors[fieldName];
    if (errorMessage) {
      return (
        <Typography className="LeagueSpartan" style={{ color: '#DC2626', fontSize: '12px' }}>
          {errorMessage}
        </Typography>
      );
    }
    return null;
  };
  
  OrangeCheckbox = withStyles({
    root: {
      borderRadius: "16px",
      padding: 0,
      color: "#64748B",
      '&$checked': {
        color: "#FAA81D",
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);
  
  renderStep2 = () => {
    return (
      <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
      
      <Box sx={webStyle.content}>
      
      <Box style={{width: "100%"}}>
      <Typography className="LeagueSpartan" style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Contact Details</Typography>
      </Box> 

      <Box style={{width: "100%", display: "flex", justifyContent: "center", gap: "16px", flexDirection: "column"}}>

      <Box style={{display:"flex", 
      paddingLeft: "40px",
      paddingRight: "40px",
      flexDirection: "column", 
      gap: "4px"}}>
        <Typography className="LeagueSpartan" style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>Email Address*</Typography>
      
        <CssTextField    
          data-test-id="email_address"            
          variant="outlined"                 
          fullWidth
          id="email_address"             
          name="email_address"
          value={this.state.data.email_address}
          onChange={(e) => this.onValueChange("email_address", e.target.value)}
          InputProps={{
            style: webStyle.inputStyle}}
        />
        {this.renderErrorMessage('email_address')}

      </Box> 

      <Box style={{paddingLeft:"40px", paddingRight:"40px", display: "flex", flexDirection:"row", gap:"16px"}}>
        
        <Box style={{display:"flex", 
            flexDirection: "column", 
            width: "100%",
            gap: "4px"}}>
          <Typography className="LeagueSpartan" style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>Phone Number</Typography>
          <CssTextField    
            data-test-id="phone_number"            
            variant="outlined"                 
            fullWidth              
            id="phone_number"             
            name="phone_number"
            value={this.state.data.phone_number}
            onChange={(e) => this.onValueChange("phone_number", e.target.value)}
            inputProps={{ maxLength: 10 }}
            InputProps={{
              style: webStyle.inputStyle}}
          />
        {this.renderErrorMessage('phone_number')}
        </Box> 

        <Box style={{display:"flex", 
             flexDirection: "column", 
             width: "100%",
             gap: "4px"}}>
          <Typography className="LeagueSpartan" style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>Alternative Phone Number</Typography>
          <CssTextField    
            data-test-id="alt_phone_number"            
            variant="outlined"                 
            fullWidth              
            id="alt_phone_number"             
            name="alt_phone_number"
            value={this.state.data.alt_phone_number}
            onChange={(e) => this.onValueChange("alt_phone_number", e.target.value)}
            inputProps={{ maxLength: 10 }}
            InputProps={{
              style: webStyle.inputStyle}}
          />
        {this.renderErrorMessage('alt_phone_number')}
        </Box>

      </Box>
      
      <Box style={{display:"flex", 
      paddingLeft: "40px",
      paddingRight: "40px",
      flexDirection: "column", 
      gap: "4px"}}>
        <Typography className="LeagueSpartan" style={{color: "#475569", fontSize: "14px", fontWeight: "700"}}>Fax Number</Typography>
      
        <CssTextField    
          data-test-id="fax_number"            
          variant="outlined"                 
          fullWidth
          id="fax_number"             
          name="fax_number"
          value={this.state.data.fax_number}
          onChange={(e) => this.onValueChange("fax_number", e.target.value)}
          inputProps={{ maxLength: 10 }}
          InputProps={{
            style: webStyle.inputStyle}}
        />
        {this.renderErrorMessage('fax_number')}

      </Box> 
    
      </Box>

      </Box>                  
     
      <Box style={{gap: "16px", width: "100%"}} sx={{   paddingTop: "10px", 
        flexDirection: "row",justifyContent: "flex-end", 
        paddingBottom: "16px",  display: "flex", borderTop: "1px solid rgb(226, 232, 240)"}}>       
        <Box style={{
          display: "flex", paddingRight: "40px", 
          gap: "16px"}}>
        <Button data-test-id="back" 
        onClick={this.handleBackClick} 
        style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
            Back
        </Button>
        <Button data-test-id="next"  disabled={this.step2Disable()}  onClick={this.handleNextClick} 
          style={  this.step2Disable()
              ? webStyle.disabledButtonStyle 
              : webStyle.buttonStyleModal 
          } size="small" >
            Next
        </Button>
        </Box>
      </Box>
      </Box>
    )
  }


  loadMap = () => {
    const { center, markerPosition, libraries } = this.state;

    return (
        <LoadScriptNext
            key={this.state.center.lat + this.state.center.lng}
            googleMapsApiKey=""
            libraries={libraries}
        >
            <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <Autocomplete
                    onLoad={(autocomplete) => this.setState({ autocomplete })}
                    onPlaceChanged={this.handlePlaceSelect}
                >
                    <CssTextField
                        variant="outlined"
                        fullWidth
                        data-test-id="autocomplete"
                        placeholder="Enter address"
                        InputProps={{
                            style: webStyle.inputStyle,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PlaceOutlined style={{ color: "#FAA81D" }} />
                                </InputAdornment>
                            )
                        }}
                    />
                </Autocomplete>

                <GoogleMap
                    mapContainerStyle={{ width: '590px', height: '364px' }}
                    center={center}
                    zoom={14}
                    options={{
                        mapTypeControl: false,
                        streetViewControl: false,
                        zoomControl: true, 
                        styles: [
                            {
                                featureType: 'all',
                                elementType: 'geometry',
                                stylers: [
                                    { visibility: 'on' }
                                ]
                            },
                            {
                                featureType: 'all',
                                elementType: 'labels',
                                stylers: [
                                    { visibility: 'on' }
                                ]
                            }
                        ],
                    }}
                    onLoad={(map) => {
                        this.setState({ mapInstance: map }); // Store the map instance when the map loads
                    }}
                >
                    <MarkerF
                        position={markerPosition}
                        draggable={true}
                        icon={{
                            url: this.location_pin,
                        }}
                        onDragEnd={(event) => {
                            const lat = event.latLng?.lat() ?? 0;
                            const lng = event.latLng?.lng() ?? 0;
                            this.setState({ markerPosition: { lat, lng } });
                            this.geocodeLatLng(lat, lng);
                        }}
                    />
                    <Button
                        data-test-id="currentLoc"
                        style={{
                            position: 'absolute',
                            bottom: '114px',
                            right: '7px',
                            zIndex: 5,
                            padding: '10px',
                            border: 'none',
                            backgroundColor: "white",
                            borderRadius: '30px',
                            color: '#fff',
                            width: "48px",
                            height: "48px"
                        }}
                    >
                        <img src={this.current_location} />
                    </Button>
                </GoogleMap>
            </div>
        </LoadScriptNext>
    );
};



  renderStep3 = () => {
    return (
      <Box key ={this.state.activeStep} style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
      
      <Box sx={webStyle.content}>
      
      <Box style={{width: "100%"}}>
      <Typography className="LeagueSpartan" style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Hotel Location</Typography>
      </Box> 

      <Box style={{width: "100%", display: "flex", justifyContent: "center", gap: "16px"}}>
      

      {this.loadMap()}
      
      </Box>

      </Box>                  
     
      <Box style={{gap: "16px", width: "100%"}} sx={{
        paddingTop: "10px", 
        flexDirection: "row",justifyContent: "flex-end", 
        paddingBottom: "16px",
        display: "flex", 
      borderTop: "1px solid rgb(226, 232, 240)"}}>       
        <Box style={{
          display: "flex", 
          paddingRight: "40px", 
          gap: "16px"}}>
        <Button data-test-id="back" onClick={this.handleBackClick} style={webStyle.buttonStyleModal2} size="small" variant="outlined" >
            Back
        </Button>
        <Button data-test-id="next" 
        disabled={Object.values(this.state.errors).some(error => error !== '')} 
          onClick={this.handleNextClick} 
          style={
            Object.values(this.state.errors).some(error => error !== '')
              ? webStyle.disabledButtonStyle 
              : webStyle.buttonStyleModal 
          } size="small" >
            Next
        </Button>
        </Box>
      </Box>
      </Box>
    )
  }



  step1Disable = () => {
    return (this.state.errors.hotel_name !== ""|| this.state.errors.hotel_rating !== ""||
    this.state.data.hotel_name == "")
  }

  step2Disable = () => {
    return (this.state.errors.email_address !== ""||this.state.errors.phone_number !== ""||
    this.state.errors.alt_phone_number !== ""|| this.state.errors.fax_number !== ""|| 
    this.state.data.email_address == ""||this.state.data.phone_number == ""||
    this.state.data.alt_phone_number == ""|| this.state.data.fax_number == "")
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
        <Box sx={webStyle.main}>

        <style>
          {`
            .gmnoprint .gmbutton {
              background-color: #FAA81D; 
              border: none;
              color: white; 
            }
            .MuiButton-root{
              min-width: 0px;
            }
            .MuiInputBase-input::placeholder {
              opacity: 1;            
            }
            .MuiList-padding {
              padding-top: 0px;
              padding-bottom: 0px;
            }
            .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
              border-color: transparent;
            }
            .MuiMobileStepper-progress {
              background: #CBD5E1;
              width: 100%;
              border-radius: 6px 0px 0px 6px;
            }
            .MuiMobileStepper-root{
              background: 0;
              padding: 0px;
            }
            .MuiLinearProgress-barColorPrimary {
              background: #CBD5E1;
            }       
            .MuiInput-underline::before .MuiInput-underline::after{
              border: 0;
            }
            .MuiInput-underline{
              border-bottom: 0px;
            }
            .MuiButton-outlined {
              border: 1px solid rgb(250, 168, 29);
            }     
            .MuiTypography-body1 {
              font-family: inherit;
            }
            .MuiOutlinedInput-input {
              padding: 0;
            }
            .MuiRadio-colorSecondary.Mui-checked {
              color: rgba(250, 168, 29, 1);
            }
            .custom-select {
              margin: 0;
              padding: 10px;
              width: 200px; 
            }
            .MuiSelect-select {
              padding: 0px; 
            }
            .MuiMenuItem-root:hover {
              background-color: #FEF3C7; 
            }
            .MuiFormControlLabel-labelPlacementStart {
              flex-direction: row-reverse;
              justify-content: space-between;
              margin-left: 0;
              margin-right: 40px;
            }
            .MuiFormControlLabel-root {
              margin-left: 0;
            }
            .styled-select .react-dropdown-select-dropdown {
              border-radius: 8px;
              border: 1px solid #cbd5e1;
              box-shadow: 0px 2px 4px 0px #00000026;
            }

            .styled-select .react-dropdown-select-item {
              height: 48px;
              display: flex;
              align-items: center;
            }

            .styled-select .react-dropdown-select-item:hover {
              background-color: #fef3c7;
            }

            .styled-select .react-dropdown-select-item.react-dropdown-select-item-selected {
              background-color: #fef3c7;
              color: #000000;
            }

            .styled-select .react-dropdown-select {
              border-radius: 8px;
              border: 1px solid #cbd5e1;
            }


            .MuiTouchRipple-root {
              display: none;
            }

            .MuiToggleButton-root{
              padding: 6px 12px 6px 12px; 
              border: 1px solid #64748B;
              font-family: inherit;
              color: #1E293B;
            }

            .MuiToggleButton-root.Mui-selected {
              background-color: #FEFCF9;
              color: #1E293B !important;
              border: 1px solid #FAA81D !important; 
            }
            .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
              border: 1px solid #64748B; 
              margin-left: 0;
            }
            .MuiFormControlLabel-labelPlacementStart {
              margin-right: -11px !important;
            }

            .ReactDropdownSelect:focus-within {
              outline: 0px;
              box-shadow: none;
            }

            .css-1o5fdv0-ReactDropdownSelect {
              color: #64748B;
            }

            .css-1ncinaz-DropDown{
              top: 55px;
            }

            input[type="file"] {
              display: none;
          }
            .custom-file-upload {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0px 16px 0px 16px;
              height: 44px;
              background: rgba(254, 252, 249, 1);
              border-radius: 8px;
              border: 1px solid rgba(250, 168, 29, 1);
              cursor: pointer;
          }
          `}
        </style>

          <Box sx={{width: "100%",height: "92px",display: "flex", flexDirection: "column"}}>
          <div style={{ display: 'flex', alignItems: 'center', height: '92px', position: 'relative', padding: "15px" }}>
            <img src={this.logoSvg} style={{marginLeft: "10px"}}/>
            <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
            <Typography className="LeagueSpartan" style={{ fontWeight: '700', fontSize: '24px' }}>
              Hotel Info
            </Typography> 
            </span>
          </div>
          <Box sx={{width: "100%", alignSelf: "center"}}>
          {this.renderStepper()}
          </Box>
          </Box>
          
          <Box style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${this.bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            alignSelf: "center",
            display: "flex",
            justifyContent: "center"
            }}>

          {this.state.activeStep == 0 && (
            <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content",margin: "3%",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  

            <span></span>
            <Box>
              <img src={this.hotel_bgImage}/>
            </Box>
            
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}} style={{ gap: "24px"}}>
              <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <Typography className="LeagueSpartan" style={{color: "#64748B", fontSize: "18px", fontWeight: "400px"}}>You currently have no property.</Typography>
              <Typography className="LeagueSpartan" style={{color: "#64748B", fontSize: "18px", fontWeight: "400px"}}>Begin your hotel business by creating your first hotel now!</Typography>
              </Box>
              <Button data-test-id="next" style={{width: "157px", height: "57px", backgroundColor: "#FAA81D", borderRadius: "8px" }}  onClick={() => this.setState((prevState) => ({ activeStep: prevState.activeStep + 1 }))} size="small">
                <Box style={{display: "flex", flexDirection: "row", gap: "8px"}}>
                <AddCircleOutlineOutlined style={{color:"white"}}/>
                <Typography className="LeagueSpartan" style={{fontSize: "16px", fontWeight: 700, color: "white"}}>Add Hotel</Typography>
                </Box>
              </Button>
            </Box>
            <span></span>

            </Box>
          )}

          {this.state.activeStep == 1 && (
            <Box style={{backgroundColor: "white", gap: "40px", alignItems:"center"}} sx={{width: "670px", height: "fit-content", marginTop: "56px",boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "40px, 0px, 24px, 0px", display: "flex", flexDirection: "column" }}>                  
            
            <Box sx={webStyle.content}>
            
            <Box style={{width: "100%"}}>
            <Typography className="LeagueSpartan" style={{fontWeight: "700", fontSize: "24px", color: "#0F172A", paddingLeft:"40px"}}>Basic Info</Typography>
            </Box> 

            <Box style={{width: "100%", display: "flex", justifyContent: "center", gap: "16px", flexDirection: "column"}}>

            <Box 
              style={{ 
                display: "flex", 
                paddingLeft: "40px",
                paddingRight: "40px",
                flexDirection: "column", 
                gap: "6px" 
              }}
            >
              <Typography className="LeagueSpartan" 
                style={{ color: "#475569", fontSize: "14px", fontWeight: "700" }}
              >
                Hotel name*
              </Typography>

              <CssTextField    
                variant="outlined"                 
                data-test-id="hotel_name"            
                fullWidth
                InputProps={{
                  style: webStyle.inputStyle,
                }}
                id="hotel_name"             
                name="hotel_name"
                value={this.state.data.hotel_name}
                onChange={(e) => this.onValueChange("hotel_name", e.target.value)}
              />
              {this.renderErrorMessage('hotel_name')}
            </Box>

            <Box 
              style={{ 
                display: "flex", 
                paddingLeft: "40px",
                paddingRight: "40px",
                flexDirection: "column", 
                gap: "4px" 
              }}
            >
              <Typography className="LeagueSpartan" 
                style={{ color: "#0F172A", fontSize: "18px", fontWeight: 700 }}
              >
                Star Rating
              </Typography>

              <Rating
                emptyIcon={<StarBorderOutlined style={{color: "#64748B"}} fontSize="inherit" />}
                name="hotel_rating"
                data-test-id="hotel_rating"
                value={this.state.data.hotel_rating}
                onChange={(_, newValue) => {
                  const errors = { ...this.state.errors };
                  if (this.state.data.star_rated && !newValue) {
                    errors.hotel_rating = "Please select a star rating as you have indicated that the hotel is star rated";
                  } else {
                    errors.hotel_rating = ""; 
                  }

                  this.setState((prevState) => ({
                    data: {
                      ...prevState.data,
                      hotel_rating: newValue,
                    },
                    errors,
                  }));
                }}
                disabled={!this.state.data.star_rated} 
              />
              {this.state.data.star_rated && this.state.data.hotel_rating == null && this.renderErrorMessage('hotel_rating')}

                
              <Box style={{display: "flex", gap: "8px", alignItems: "center", paddingTop: "8px"}}>
              <this.OrangeCheckbox data-test-id="star_rated"
              icon={<img src={this.checkbox}/>}
               checkedIcon={<img src={this.checkbox_fill}/>}
                name="star_rated"
                onChange={() => {
                  const errors = { ...this.state.errors };
                  if (!this.state.data.star_rated && this.state.data.hotel_rating == null) {
                    errors.hotel_rating = "Please select a star rating as you have indicated that the hotel is star rated";
                  } else {
                    errors.hotel_rating = ""; 
                  }

                  this.setState((prevState) => ({
                    data: {
                      ...prevState.data,
                      star_rated: !prevState.data.star_rated, 
                    },
                    errors,
                  }))
                            
                }}
                checked={this.state.data.star_rated}
              />
              <Typography className="LeagueSpartan" 
                style={{ color: "#0F172A", fontSize: "16px", fontWeight: 400 }}
              >
                Star Rated
              </Typography>
              </Box>

            </Box>

            </Box>
            </Box>                  
           
            <Box style={{gap: "16px", width: "100%"}} sx={{
        paddingTop: "10px", flexDirection: "row",justifyContent: "flex-end", 
        paddingBottom: "16px",
        display: "flex", 
      borderTop: "1px solid rgb(226, 232, 240)"}}>       
        <Box style={{
          display: "flex", paddingRight: "40px",  gap: "16px"}}>
        <Button data-test-id="back" onClick={this.handleBackClick} style={webStyle.buttonStyleModal2} 
        size="small" variant="outlined" >
            Back
        </Button>
        <Button data-test-id="next" 
        disabled={this.step1Disable()} 
          onClick={this.handleNextClick} style={
            this.step1Disable()
              ? webStyle.disabledButtonStyle 
              : webStyle.buttonStyleModal 
          } size="small" >
            Next
        </Button>
        </Box></Box></Box>
          )}

          {this.state.activeStep == 2 && this.renderStep2()}
          
          {this.state.activeStep == 3 && this.renderStep3()}
               

          </Box>
        </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const webStyle = {
  // custom style definitions
  main: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    fontFamily: "League Spartan, Arial, sans-serif",
    flexDirection: "column"
  },
  paperStyleS: {
    display: 'flex', 
    alignItems: 'center', 
    fontSize: "16px",
    padding: "16px",
    borderRadius: "8px", 
    boxShadow: "0px 2px 8px 0px #0000001A"
  },

  paperStyle: {
    padding: "10px",
    borderRadius: "8px", 
    boxShadow: "0px 2px 8px 0px #0000001A"
  },

  helperText: {
    textAlign: "right",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "24px",
    padding: "40px 40px 0px 40px",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  disabledButtonStyle: {
    color: "#64748B",
    backgroundColor: "#F1F5F9",
    border: 0,
    borderRadius: "8px",
    fontSize: "16px",
    width: "88px",
    height: "56px",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "88px",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal6: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "117px",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal2: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "88px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal3: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "100%",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal4: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "117px",
    height: "56px",
    color: "white",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },

  buttonStyleModal5: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "108px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  inputStyle: {
    width: "100%",
    height: "56px",
    padding: "14px",
    gap: "8px",
    borderRadius: "8px",
    color: "#0f172A",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  textStyle : {
    color: "#475569",
    fontSize: "14px",
    fontWeight: "700",
    fontFamily: "League Spartan, Arial, sans-serif",
  },
  textStyle2 : {
    color: "#475569",
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "League Spartan, Arial, sans-serif",
  }
};
// Customizable Area End