import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Switch,
  SwitchProps,
} from "@material-ui/core";
import { GoogleMap, Marker } from '@react-google-maps/api';
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { addDays, subDays, addMonths, differenceInDays, endOfMonth, getDate, getDay, startOfDay, startOfMonth, subMonths, isEqual } from "date-fns";
const ids = {
  roundTripSwitch: "round-trip-switch",
  searchAddressDropDown: "search-location-dropdown",
  searchInputAddress: "search-input-address",
  searchDropDownIconStart: "search-dropdown-icon-start",
  searchDropDownClose: "search-dropdown-close",
  searchSuggestions: "search-suggestions",
  searchSuggestion: "search-suggestion",
  searchCalendar: "search-calendar",
  searchTimeSheet: "search-time-sheet",
  searchTimeField: "search-time-field",
  searchTimeFieldBtn: "search-time-field-btn",
  searchTimeOption: "search-time-option",
  searchCalendarBtn: "search-calendar-btn",
  searchSelectedDates: "search-selected-dates",
  searchDateBtn: "search-date-btn",
  searchPrevMonthBtn: "search-prev-month-btn",
  searchNextMonthBtn: "search-next-month-btn",
  searchMonth: "search-month",
  searchBtn: "search-btn",
  filterPriceMin: "filter-price-min",
  filterPriceMax: "filter-price-max",
  filterClearBtn: "filter-clear-btn",
  vehicleType: "vehicleType-checkbox",
  fuleType: "fuleType-checkbox",
  transmission: "transmission-checkbox",
  purposeUsage: "purpose-usage-checkbox",
  specialFeature: "special-feature-checkbox",
  sittingCapacity: "sitting-capacity-checkbox",
  comfortEnt: "comfort-entertainment-checkbox",
  sortBtn: "sort-btn",
  sortList: "sort-list",
  sortOption: "sort-option",
  mapToggleButton: "map-toggle-button",
  mapCloseButton: "map-close-button",
  carCardBtn: "car-btn",
  recommCarCardBtn: "recomm-car-btn",
};

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const categories = ['Category', 'Price (Lowest First)', 'Price (Highest First)', 'A - Z sorting', 'Z - A sorting'];
const cars = [
  {
    id: 1,
    name: 'Volkswagen Golf 2021',
    location: 'ABC Motors, Gatwick, London, UK',
    price: 89,
    taxes: 45.30,
    guestsCount: 2,
    coordinates: { lat: 52.353400, lng: 4.870250 },
  },
  {
    id: 2,
    name: 'Radisson Blu',
    location: 'Park Lane, London',
    price: 89,
    taxes: 45.30,
    guestsCount: 3,
    coordinates: { lat: 52.355447, lng: 4.869128 },
  },
  {
    id: 3,
    name: 'Hilton Hotel',
    location: 'Hyde Park, London',
    price: 89,
    taxes: 45.30,
    guestsCount: 2,
    coordinates: { lat: 52.355249, lng: 4.872622 },
  },
];

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    texts: Palette['primary'];
    cardText: Palette['primary'];
    accent: Palette['primary'];
    backgrounds: Palette['primary'];
    cardBackground: Palette['primary'];
    buttonBackgroundDisabled: Palette['primary'];
    buttonDisabled: Palette['primary'];
    buttonText: Palette['primary'];
    borders: Palette['primary'];
    unselected: Palette['primary'];
    discount: Palette['primary'];
    boxShadow: Palette['primary'];
    switch: Palette['primary'];
  }
  interface PaletteOptions {
    texts?: PaletteOptions['primary'];
    cardText?: PaletteOptions['primary'];
    accent?: PaletteOptions['primary'];
    backgrounds?: PaletteOptions['primary'];
    cardBackground?: PaletteOptions['primary'];
    buttonBackgroundDisabled?: PaletteOptions['primary'];
    buttonDisabled?: PaletteOptions['primary'];
    buttonText?: PaletteOptions['primary'];
    borders?: PaletteOptions['primary'];
    unselected?: PaletteOptions['primary'];
    discount?: PaletteOptions['primary'];
    boxShadow?: PaletteOptions['primary'];
    switch?: PaletteOptions['primary'];
  }
}

interface Car {
  id: number,
  name: string,
  location: string,
  price: number,
  taxes: number,
  guestsCount: number,
  coordinates: { lat: number, lng: number },
}

// Customizable Area End

const theme = createTheme({
  palette: {
    texts: { main: "#0F172A", light: "#64748B", dark: "#475569", contrastText: "#1E293B" },
    cardText: { main: "#334155" },
    accent: { main: "#FAA81D", light: "#FEF3C7", dark: "#D97706", contrastText: "#FFCC7C" },
    backgrounds: { main: "#FEFCF9", light: "#FFFFFF" },
    cardBackground: { main: "#C1C1C11F" },
    buttonBackgroundDisabled: { main: "#F1F5F9" },
    buttonDisabled: { main: "#94A3B8" },
    borders: { main: "#CBD5E1", light: "#E2E8F0" },
    discount: { main: "#DC2626" },
    unselected: { main: "#64748B" },
    boxShadow: { main: "#0000001A" },
    switch: { main: "#E2E8F0" },
  },
});

const styles = {
  container: {
    display: 'flex',
    maxWidth: '100%',
    width: '100wh',
    minWidth: 1080,
    padding: 0,
    margin: 0,
  },
  navContainer: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingInline: 24,
    paddingBlock: 20,
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: theme.palette.borders.main,
    borderStyle: 'solid'
  },
  navLogo: {
    maxWidth: 50,
    marginBottom: 40,
  },
  navList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
  },
  navItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightSideContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    width: '100%',
    backgroundColor: theme.palette.backgrounds.main,
  },
  navText: {
    color: theme.palette.unselected.main,
  },
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.backgrounds.light,
    paddingBlock: 26,
    paddingInline: 48,
    maxHeight: 40,
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.texts.main,
  },
  headerIcons: {
    display: 'flex',
    gap: 52,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 24,
    paddingInline: 48,
  },
  searchContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: 32,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.accent.main,
    backgroundColor: theme.palette.backgrounds.light,
    borderRadius: 8,
    boxShadow: `0px 2px 8px 0px ${theme.palette.boxShadow.main}`
  },
  tripDetailsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tripDetailsTitle: {
    fontSize: 24,
    fontWeight: 700,
    color: theme.palette.texts.main,
  },
  roundTripContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 16,
  },
  roundTripText: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.texts.dark,
  },
  searchFieldsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    gap: 8,
  },
  searchInputLabel: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.texts.main,
  },
  searchInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 4,
    flexBasis: 129,
  },
  searchAddressInputContainer: {
    flexBasis: 'auto',
    flexGrow: 1,
  },
  searchDropDown: {
    position: 'relative' as 'relative',
    display: 'flex',
    maxHeight: 56,
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: theme.palette.backgrounds.light,
    transition: 'border-color .3s ease',
  },
  searchDropDownContainer: {
    position: 'relative' as 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingInline: 48,
    paddingBlock: 16,
    maxHeight: 24,
  },
  searchDropDownCalendarContainer: {
    paddingLeft: 48,
    paddingRight: 16,
  },
  searchDropDownIconStart: {
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 16,
    maxWidth: 24,
    maxHeight: 24,
  },
  searchDropDownButtonEnd: {
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 16,
    border: 'none',
    padding: 0,
    backgroundColor: theme.palette.backgrounds.light,
    maxWidth: 24,
    maxHeight: 24,
    cursor: 'pointer',
    zIndex: 10,
  },
  searchDropDownIconEnd: {
    maxWidth: 24,
    maxHeight: 24,
  },
  searchInput: {
    fontSize: 14,
    color: theme.palette.texts.main,
    fontWeight: 400,
    outline: 0,
    borderWidth: 0,
    paddingBlock: 16,
    borderRadius: 8,
    width: '100%',
  },
  searchDropDownList: {
    overflow: 'hidden',
    position: 'absolute',
    top: '120%',
    width: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.borders.main,
    borderRadius: 8,
    boxShadow: `0px 2px 8px 0px ${theme.palette.boxShadow.main}`,
    backgroundColor: theme.palette.backgrounds.light,
    zIndex: 1000,
  },
  searchDropDownTimeSheet: {
    width: 'max-content',
    borderWidth: 0,
  },
  searchDropDownListLocation: {
    minWidth: 'max-content',
    left: 0,
  },
  searchDropDownListCalendar: {
    minWidth: 316,
    padding: 24,
    left: 0,
  },
  searchInputAddress: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.texts.main,
    padding: 12,
    maxWidth: 420,
    cursor: 'pointer',
  },
  searchInputTextContainer: {
    position: 'relative' as 'relative',
    overflow: 'hidden',
    width: '100%',
    minHeight: 24,
  },
  searchInputText: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    fontSize: 14,
    color: theme.palette.texts.main,
    fontWeight: 400,
    outline: 0,
    minWidth: 'max-content',
  },
  searchCalendarInfo: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
    gridTemplateRows: 'repeat(2, 24px)',
    alignItems: 'center',
    rowGap: 16,
    columnGap: 6,
    marginBottom: 8,
  },
  searchCalendarDates: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, minmax(0, 40px))',
    gap: 6,
    paddingTop: 0,
  },
  searchCalendarMonth: {
    gridArea: '1 / 2 / 2 / -2',
    fontSize: 16,
    fontWeight: 700,
    margin: 0,
    textAlign: 'center' as 'center',
    color: theme.palette.texts.main,
  },
  searchCalendaryButton: {
    border: 'none',
    background: 'transparent',
    minWidth: 40,
    minHeight: 24,
  },
  searchCalendarDay: {
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    textAlign: 'center' as 'center',
    color: theme.palette.texts.main,
  },
  searchCalendarDate: {
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    minWidth: 40,
    minHeight: 40,
    color: theme.palette.texts.main,
    borderWidth: 0,
    background: 'none',
    backgroundColor: theme.palette.backgrounds.light,
    cursor: 'pointer',
  },
  disabledDate: {
    color: theme.palette.unselected.main,
    cursor: 'not-allowed',
  },
  currentDay: {
    fontWeight: '700',
  },
  defaultDate: {
    border: 'none',
  },
  selectedDate: {
    backgroundColor: theme.palette.accent.light,
    borderRadius: '50%',
  },
  startDate: {
    backgroundColor: theme.palette.accent.light,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.accent.main,
    borderRadius: '50% 0 0 50%',
    color: theme.palette.accent.dark,
  },
  endDate: {
    backgroundColor: theme.palette.accent.light,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.accent.main,
    borderRadius: '0 50% 50% 0',
    color: theme.palette.accent.dark,
  },
  searchTimeSheetButton: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: 161,
    padding: 12,
    paddingRight: 16,
    color: theme.palette.texts.main,
  },
  searchTimeSheetDisabled: {
    color: theme.palette.texts.dark,
    pointerEvent: 'none' as 'none',
  },
  searchTimeSheetTitle: {
    fontSize: 16,
    fontWeight: 400,
  },
  searchTimeSheetIcon: {
    maxWidth: 24,
    maxHeight: 24,
  },
  searchButtonDone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    paddingBlock: 6,
    fontWeight: 700,
    color: theme.palette.accent.main,
    background: 'transparent',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.accent.main,
    borderRadius: 8,
  },
  searchButton: {
    boxSizing: 'border-box' as 'border-box',
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.backgrounds.light,
    fontSize: 14,
    fontWeight: 700,
    padding: 16,
    borderRadius: 8,
    minWidth: 150,
    maxHeight: 56,
  },
  mainContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexGrow: 1,
    gap: 32,
  },
  mainContentContainerDisabled: {
    display: 'none',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.backgrounds.light,
    borderRadius: 8,
    boxShadow: `0px 2px 8px 0px ${theme.palette.boxShadow.main}`,
    width: 288,
  },
  filterHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 24,
    paddingBottom: 20,
    paddingInline: 32,
    maxHeight: 28,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.borders.light,
  },
  filterHeading: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.texts.main,
  },
  filterClearButton: {
    color: theme.palette.accent.main,
    fontWeight: 700,
    fontSize: 14,
  },
  filterContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 42,
    paddingTop: 20,
    paddingInline: 32,
    paddingBottom: 32,
  },
  filterTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.texts.main,
  },
  filterSect: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  filterSectList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 22,
  },
  filterSectPricing: {
    display: 'flex',
    gap: 16,
  },
  filterSectItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  filterSectInput: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  filterSectCheckbox: {
    position: 'relative' as 'relative',
    overflow: 'hidden',
    width: 24,
    height: 24,
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  filterSectCheckboxIcons: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.accent.main,
    transition: 'opacity .3s ease',
  },
  filterSectCheckboxIcon: {
    maxWidth: 18,
    maxHeight: 18,
  },
  filterSectToggler: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    margin: 0,
    cursor: 'pointer',
  },
  filterSectOpt: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  },
  filterSectOptTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.texts.main,
  },
  filterSectRadioContainer: {
    position: 'relative' as 'relative',
    width: 20,
    height: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '50%',
  },
  filterSectRadio: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    backgroundColor: theme.palette.backgrounds.light,
    boxShadow: `inset 0 0 0 6px ${theme.palette.accent.main}`,
    transition: 'opacity .3s ease',
  },
  fitlerSectRadioInput: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    opacity: 0,
    margin: 0,
  },
  filterInputContainer: {
    position: 'relative' as 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexGrow: 1,
    paddingLeft: 48,
    paddingRight: 12,
    paddingBlock: 12,
    borderWidth: 1,
    borderRadius: 8,
    borderStyle: 'solid',
    backgroundColor: theme.palette.backgrounds.light,
    transition: 'border-color .3s ease',
  },
  filterPriceContainer: {
    paddingLeft: 39,
  },
  filterInputLabel: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.texts.dark,
  },
  filterInput: {
    width: '100%',
    fontSize: 14,
    maxHeight: 24,
    color: theme.palette.texts.main,
    fontWeight: 400,
    borderWidth: 0,
    outline: 0,
  },
  filterInputIcon: {
    position: 'absolute' as 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: 16,
    maxWidth: 24,
    maxHeight: 24,
  },
  filterStarRatings: {
    minWidth: 14,
    minHeight: 14,
  },
  filterShowAll: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.accent.main,
    cursor: 'pointer',
  },
  carGridContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 40,
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flexGrow: 1,
    gap: 32,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.borders.light,
    paddingTop: 32,
    paddingBottom: 14,
  },
  toolbarButtonContainer: {
    position: 'relative' as 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 12,
    border: 'none',
    background: 'transparent' as 'transparent',
  },
  toolbarButton: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    fontSize: 18,
    fontWeight: 700,
    textTransform: 'none' as 'none',
    color: theme.palette.accent.main,
  },
  toolbarButtonIcon: {
    maxWidth: 24,
    maxHeight: 24,
  },
  categoryList: {
    position: 'absolute' as 'absolute',
    top: '130%',
    right: 0,
    minWidth: 'max-content',
    backgroundColor: theme.palette.backgrounds.light,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#F5F5F5',
    borderRadius: 8,
    overflow: 'hidden',
    boxShadow: `0px 2px 8px 0px ${theme.palette.boxShadow.main}`,
    zIndex: 100,
  },
  categoryItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: 14,
    fontWeight: 400,
    width: '100%',
    color: theme.palette.texts.main,
    padding: 12,
    margin: 0,
    borderRadius: 0,
    textTransform: 'none' as 'none',
  },
  categoryItemSelected: {
    backgroundColor: theme.palette.accent.contrastText,
  },
  categoryItemDisabled: {
    pointerEvents: 'none' as 'none',
  },
  recomCarsContainer: {
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    overflow: 'hidden',
    minHeight: 376,
  },
  recomCarsTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.cardText.main,
  },
  recomCarsListContainer: {
    position: 'relative' as 'relative',
    width: '100%',
  },
  recomCarsList: {
    position: 'absolute' as 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    gap: 16,
  },
  recomCarItem: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: 216,
    borderRadius: 8,
    backgroundColor: theme.palette.cardBackground.main,
  },
  recomCarContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: 16,
  },
  recomCarDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  recomCarName: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.cardText.main,
  },
  recomCarLocation: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.texts.light,
  },
  recomCarPricing: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  recomCarPriceLabel: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.texts.dark,
  },
  recomCarPriceContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  recomCarCurrentPrice: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.texts.contrastText,
  },
  recomCarOriginalPrice: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 2,
    color: theme.palette.texts.dark,
  },
  recomCarDiscountContainer: {
    position: 'relative' as 'relative',
  },
  recomCarDiscount: {
    position: 'absolute' as 'absolute',
    top: '50%',
    transfrom: 'translateY(-50%)',
    left: 0,
    right: 0,
    backgroundColor: theme.palette.discount.main,
    minHeight: 1,
  },
  recomSelectButton: {
    fontSize: 16,
    fontWeight: 700,
    paddingTop: 6,
    paddingBottom: 4,
    paddingInline: 10,
    maxHeight: 32,
    backgroundColor: theme.palette.accent.main,
    borderRadius: 8,
    color: theme.palette.backgrounds.light,
  },
  allCarsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  allCarsTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: theme.palette.cardText.main,
  },
  allCarsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  allCarItem: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexGrow: 1,
    backgroundColor: theme.palette.backgrounds.light,
    padding: 32,
    borderRadius: 8,
    boxShadow: `0px 2px 8px 0 ${theme.palette.boxShadow.main}`,
  },
  allCarItemContent: {
    display: 'flex',
    gap: 24,
  },
  allCarDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    justifyContent: 'space-between',
  },
  allCarInfo: {

  },
  allCarName: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.cardText.main,
  },
  allCarLocationContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  allCarLocation: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.texts.light,
  },
  allCarLocationIcon: {
    maxWidth: 18,
    maxHeight: 18,
  },
  allCarPricing: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 6,
  },
  allCarPriceLabel: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.texts.dark,
  },
  allCarPriceContainer: {
    display: 'flex',
    gap: 12,
  },
  allCarCurrentPrice: {
    fontSIze: 14,
    fontWeight: 700,
    color: theme.palette.texts.contrastText,
  },
  allCarOriginalPrice: {
    fontSize: 12,
    fontWeight: 400,
    maxHeight: 18,
    color: theme.palette.texts.dark,
  },
  allCarDiscountContainer: {
    position: 'relative' as 'relative',
    paddingTop: 4,
    maxHeight: 18,
  },
  allCarDiscount: {
    position: 'absolute' as 'absolute',
    top: '55%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
    minHeight: 1,
    backgroundColor: theme.palette.discount.main,
  },
  allCarSelectButton: {
    minWidth: 184,
    minHeight: 42,
    fontSize: 16,
    fontWeight: 700,
    paddingTop: 6,
    paddingBottom: 3,
    paddingInline: 10,
    backgroundColor: theme.palette.accent.main,
    borderRadius: 8,
    color: theme.palette.backgrounds.light,
  },
  carDetailsContainer: {
    display: 'flex',
    gap: 20,
    paddingBlock: 4,
  },
  carDetailItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    paddingRight: 20,
    border: 'none',
    borderRight: `1px solid ${theme.palette.borders.main}`,
  },
  carDetailItemNoBorder: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    paddingRight: 20,
    border: 'none',
  },
  carPricingContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    justifyContent: 'space-between',
  },
  carMap: {
    display: 'flex',
    gap: 24,
  },
  carMapDisabled: {
    display: 'none',
  },
  carList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    flexBasis: '33%',
  },
  carCard: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    gap: 24,
    paddingInline: 16,
    paddingBlock: 12,
    borderRadius: 8,
    boxShadow: `0px 2px 8px 0px ${theme.palette.boxShadow.main}`,
  },
  carCardSelected: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.accent.main,
  },
  carCardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    gap: 12,
  },
  carCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 24,
  },
  carCardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 24,
  },
  carImage: {
    maxWidth: 89,
    minHeight: '100%',
    borderRadius: 8,
    objectFit: 'cover' as 'cover',
  },
  carInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  carName: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.texts.main
  },
  carLocation: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.texts.dark,
  },
  carRoomType: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.texts.dark,
  },
  carTaxes: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.texts.light,
  },
  carPricing: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  carPriceLabel: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.texts.light,
  },
  carPrice: {
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.accent.main,
  },
  carPriceText: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.texts.dark,
  },
  carPassengers: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    gap: 6,
  },
  carPassengersIcon: {
    maxWidth: 16,
    maxHeight: 16,
  },
  carPassengersCount: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '1rem',
    color: theme.palette.texts.dark
  },
  map: {
    position: 'sticky' as 'sticky',
    top: 10,
    display: 'flex',
    flexBasis: '66%',
    maxHeight: 414,
  },
  mapContainer: {
    position: 'relative' as 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  mapCloseButton: {
    position: 'absolute' as 'absolute',
    top: 16,
    right: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 6,
    paddingInline: 10,
    paddingBlock: 4,
    borderRadius: 8,
    backgroundColor: theme.palette.backgrounds.light,
    border: `1px solid ${theme.palette.accent.main}`,
    zIndex: 100,
    cursor: 'pointer',
  },
  mapCloseIcon: {
    maxWidth: 24,
    maxHeight: 24
  },
  mapCloseText: {
    fontSize: 16,
    fontWeight: 700,
    color: theme.palette.accent.main,
    textTransform: 'uppercase' as 'uppercase',
  },
  mapView: {
    position: 'absolute' as 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  mapPrice: {
    position: 'relative' as 'relative',
    backgroundColor: theme.palette.backgrounds.light,
    borderRadius: 8,
    color: theme.palette.texts.dark,
    fontSize: 14,
    fontWeight: 700,
    paddingBlock: 5,
    paddingInline: 14,
    border: `1px solid ${theme.palette.accent.main}`,
    minWidth: 'max-content',
    textAlign: 'center' as 'center',
  },
  mapPriceSel: {
    fontSize: 16,
    paddingInline: 19,
    paddingBlock: 6,
    color: theme.palette.backgrounds.light,
    backgroundColor: theme.palette.accent.main,
  },
  mapTriangleBorder: {
    position: 'absolute' as 'absolute',
    left: '50%',
    top: '100%',
    transform: 'translate(-50%, 0)',
    width: 0,
    height: 0,
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: `10px solid ${theme.palette.accent.main}`,
  },
  mapTriangleInner: {
    position: 'absolute' as 'absolute',
    left: '50%',
    top: '100%',
    transform: 'translate(-50%, 0)',
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: `8px solid ${theme.palette.backgrounds.light}`,
  },
  mapTriangleInnerSelected: {
    borderTop: `6px solid ${theme.palette.accent.main}`,
  },
  mapDot: {
    position: 'absolute' as 'absolute',
    left: '50%',
    top: '180%',
    transform: 'translate(-50%, -10px)',
    width: 16,
    height: 16,
    border: `4px solid ${theme.palette.backgrounds.light}`,
    backgroundColor: theme.palette.accent.main,
    borderRadius: '50%',
  },
};

const TimeSheetList = styled(Box)(({ theme }) => ({
  maxHeight: 240,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.borders.main,
    width: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.accent.main,
    borderRadius: '10px',
    width: '4px',
  },
  scrollbarColor: `${theme.palette.accent.main} ${theme.palette.borders.main}`,
}));

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 28,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.accent.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.accent.main,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.switch.main,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

// Customizable Area End

import EndUserCarAdvancedController, { Props } from "./EndUserCarAdvancedController";

export default class EndUserCarAdvanced extends EndUserCarAdvancedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      isDateDisabledStartCalendar,
      isDateDisabledEndCalendar,
      handleAddressChange,
      handleAddressClear,
      handleFocus,
      handleFocusClick,
      handleBlur,
      handleSelectSuggestion,
      highlightMatch,
      handleChangePrice,
      handleChangeCheckbox,
      handleChangeSittingCapacity,
      handleChangeMonth,
      handleClear,
      handlePriceBlur,
      handleSelectCategory,
      handleToggleMap,
      formatDateFullMonth,
      handleStartDateClick,
      handleEndDateClick,
      formatDateDayMonth,
      isStartTimeDisabled,
      isEndTimeDisabled,
    } = this;
    const {
      searchAddress,
      suggestions,
      focusedIndex,
      refsArray,
      currentDate,
      selectedStartDate,
      selectedEndDate,
      pickupTime,
      dropoffTime,
      minPrice,
      maxPrice,
      vehicleType,
      fuleType,
      transmissionType,
      purposeUsage,
      specialFeature,
      comfortEnt,
      sittingCapacity,
      selectedCategory,
      isMapOpened,
      selectedCar,
    } = this.state;

    const startDate = startOfMonth(currentDate);
    const endDate = endOfMonth(currentDate);
    const numDays = differenceInDays(endDate, startDate) + 1;
    const prefixDays = (getDay(startDate) + 6) % 7;
    const suffixDays = (7 - getDay(endDate) - 1 + 7) % 7;
    const prevMonthEndDate = endOfMonth(subMonths(currentDate, 1));
    const nextMonthStartDate = startOfMonth(addMonths(currentDate, 1));
    const handlePrevMonth = () => handleChangeMonth(subMonths(currentDate, 1));
    const handleNextMonth = () => handleChangeMonth(addMonths(currentDate, 1));

    const timeOptions = [
      '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM',
      '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM',
      '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM'
    ];

    const navList = [
      { label: 'My Trips', icon: require('../assets/mytrips.svg'), isActive: false },
      { label: 'Cars', icon: require('../assets/car.svg'), isActive: false },
      { label: 'Cars', icon: require('../assets/selected_hotel.svg'), isActive: true },
      { label: 'Activities', icon: require('../assets/activity.svg'), isActive: false },
      { label: 'Settings', icon: require('../assets/settings.svg'), isActive: false }
    ];

    const renderNavList = () =>
      navList.map(({ label, icon, isActive }) => (
        <Box key={label} sx={styles.navItem}>
          <img src={icon} alt={label} />
          <Typography style={{
            ...styles.navText,
            color: isActive ? theme.palette.accent.main : theme.palette.unselected.main,
          }}>
            {label}
          </Typography>
        </Box>
      ));

    const SuggestionsList = () => (
      <Box data-test-id={ids.searchSuggestions} sx={{ ...styles.searchDropDownList, ...styles.searchDropDownListLocation }}>
        {suggestions.map((suggestion, index) => (
          <Typography
            data-test-id={ids.searchSuggestion}
            key={index}
            onMouseDown={() => handleSelectSuggestion(suggestion)}
            style={styles.searchInputAddress}
          >
            {highlightMatch(suggestion, searchAddress)}
          </Typography>
        ))}
      </Box>
    );

    const renderSuggestionsList = () => (suggestions.length > 0 && focusedIndex === 0) ? SuggestionsList() : null;

    const CloseButton = () => (
      <button data-test-id={ids.searchDropDownClose} style={styles.searchDropDownButtonEnd} onClick={handleAddressClear}>
        <img
          style={styles.searchDropDownIconEnd}
          src={require('../assets/close.svg')}
          alt="close"
        />
      </button>
    );

    const renderCloseButton = () => searchAddress.length > 0 ? CloseButton() : null;

    const renderAddressDropDown = () => {
      const isFocused = focusedIndex === 0;
      const locationIcon = require('../assets/location.svg');
      return (
        <div
          data-test-id={ids.searchAddressDropDown}
          style={{
            ...styles.searchDropDown,
            borderColor: isFocused ? theme.palette.accent.main : theme.palette.borders.main
          }}
          ref={refsArray[0] as React.RefObject<HTMLInputElement>}
        >
          <div style={styles.searchDropDownContainer}>
            <img
              style={styles.searchDropDownIconStart}
              src={locationIcon}
              alt="location"
            />
            <input
              data-test-id={ids.searchInputAddress}
              style={styles.searchInput}
              type="text"
              onChange={handleAddressChange}
              value={searchAddress}
              placeholder="Enter address"
              onFocus={() => handleFocus(0)}
              onBlur={handleBlur}
            />
          </div>

          {renderCloseButton()}

          {renderSuggestionsList()}
        </div>
      );
    };

    const renderAddressSearch = () => {
      return (
        <Box sx={{ ...styles.searchInputContainer, ...styles.searchAddressInputContainer }}>
          <Typography style={styles.searchInputLabel}>Pick-up location</Typography>
          {renderAddressDropDown()}
        </Box>
      );
    };

    const renderCalendarDays = (
      dates: Date[],
      getDateKey: (index: number) => string,
      selectedDate: Date | null,
      onDateClick: (date: Date) => void,
      isDateDisabled: (date: Date) => boolean
    ) =>
      dates.map((date, index) => {
        const isSelected = selectedDate && isEqual(date, selectedDate);
        return (
          <Button
            data-test-id={ids.searchDateBtn}
            key={getDateKey(index)}
            onClick={() => onDateClick(date)}
            style={{
              ...styles.searchCalendarDate,
              ...(isDateDisabled(date) && styles.disabledDate),
              ...(isSelected && styles.selectedDate),
              ...(this.isCurrentDay(date) && styles.currentDay),
            }}
            disabled={isDateDisabled(date)}
          >
            {getDate(date)}
          </Button>
        );
      });

    const Calendar = (
      selectedDate: Date | null,
      onDateClick: (date: Date) => void,
      isDateDisabled: (date: Date) => boolean
    ) => (
      <Box data-test-id={ids.searchCalendar} sx={{ ...styles.searchDropDownList, ...styles.searchDropDownListCalendar }}>
        <Box sx={styles.searchCalendarInfo}>
          <Button data-test-id={ids.searchPrevMonthBtn} style={styles.searchCalendaryButton} onClick={handlePrevMonth}>
            <img src={require('../assets/arrow-calendar-left.svg')} alt="prev" />
          </Button>
          <Typography data-test-id={ids.searchMonth} style={styles.searchCalendarMonth}>
            {formatDateFullMonth(this.state.currentDate)}
          </Typography>
          <Button data-test-id={ids.searchNextMonthBtn} style={styles.searchCalendaryButton} onClick={handleNextMonth}>
            <img src={require('../assets/arrow-calendar-right.svg')} alt="next" />
          </Button>
          {daysOfWeek.map((day) => (
            <Typography key={day} style={styles.searchCalendarDay}>
              {day}
            </Typography>
          ))}
        </Box>
        <Box sx={styles.searchCalendarDates}>
          {renderCalendarDays(
            Array.from({ length: prefixDays }).map((_, index) =>
              startOfDay(subDays(prevMonthEndDate, prefixDays - index - 1))
            ),
            (index) => `prefix-${index}`,
            selectedDate,
            onDateClick,
            isDateDisabled
          )}
          {renderCalendarDays(
            Array.from({ length: numDays }).map((_, index) =>
              startOfDay(new Date(this.state.currentDate.getFullYear(), this.state.currentDate.getMonth(), index + 1))
            ),
            (index) => `date-${index + 1}`,
            selectedDate,
            onDateClick,
            isDateDisabled
          )}
          {renderCalendarDays(
            Array.from({ length: suffixDays }).map((_, index) =>
              startOfDay(addDays(nextMonthStartDate, index))
            ),
            (index) => `suffix-${index}`,
            selectedDate,
            onDateClick,
            isDateDisabled
          )}
        </Box>
      </Box>
    );

    const renderCalendarDropDown = (
      selectedDate: Date | null,
      focusedIndexCalendar: number,
      isFocused: boolean,
      initialText: string,
      onDateClick: (date: Date) => void,
      isDateDisabled: (date: Date) => boolean
    ) => {
      const selectedDateText = selectedDate ? formatDateDayMonth(selectedDate) : initialText;
      const calendarIcon = require('../assets/calendar.svg');
      return (
        <div
          style={{
            ...styles.searchDropDown,
            borderColor: isFocused ? theme.palette.accent.main : theme.palette.borders.main,
          }}
          ref={refsArray[focusedIndexCalendar] as React.RefObject<HTMLInputElement>}
        >
          <div
            data-test-id={ids.searchCalendarBtn}
            style={{ ...styles.searchDropDownContainer, ...styles.searchDropDownCalendarContainer }}
            onClick={() => handleFocusClick(focusedIndexCalendar)}
          >
            <img
              style={styles.searchDropDownIconStart}
              src={calendarIcon}
              alt="calendar"
            />
            <Box sx={styles.searchInputTextContainer}>
              <Typography data-test-id={ids.searchSelectedDates} style={styles.searchInputText}>
                {selectedDateText}
              </Typography>
            </Box>
          </div>
          {isFocused && Calendar(selectedDate, onDateClick, isDateDisabled)}
        </div>
      );
    };

    const renderStartDateCalendar = () => {
      return (
        <Box sx={styles.searchInputContainer}>
          <Typography style={styles.searchInputLabel}>Start date</Typography>
          {renderCalendarDropDown(
            selectedStartDate,
            1,
            focusedIndex === 1,
            'Select Start Date',
            handleStartDateClick,
            isDateDisabledStartCalendar
          )}
        </Box>
      );
    };

    const renderEndDateCalendar = () => {
      return (
        <Box sx={styles.searchInputContainer}>
          <Typography style={styles.searchInputLabel}>End date</Typography>
          {renderCalendarDropDown(
            selectedEndDate,
            2,
            focusedIndex === 2,
            'Select End Date',
            handleEndDateClick,
            isDateDisabledEndCalendar
          )}
        </Box>
      );
    };

    const renderTimeSheetList = (selectedTime: string, field: 'pickupTime' | 'dropoffTime') => {
      return (
        <Box data-test-id={ids.searchTimeSheet} sx={{ ...styles.searchDropDownList, ...styles.searchDropDownTimeSheet }}>
          <TimeSheetList>
            {timeOptions.map((time: string) => {
              const isDisabled = field === 'pickupTime' ? isStartTimeDisabled() : isEndTimeDisabled(time);
              return (
                <Button
                  data-test-id={ids.searchTimeOption}
                  key={time}
                  style={{
                    ...styles.searchTimeSheetButton,
                    ...(isDisabled && styles.searchTimeSheetDisabled),
                  }}
                  onClick={() => this.handleTimeSelect(field, time)}
                  disabled={isDisabled}
                >
                  <Typography style={styles.searchTimeSheetTitle}>
                    {time}
                  </Typography>
                  {selectedTime === time && (
                    <img style={styles.searchTimeSheetIcon} src={require('../assets/selected.svg')} alt="selected" />
                  )}
                </Button>
              );
            })}
          </TimeSheetList>
        </Box>
      );
    };

    const renderTimeField = (
      label: string,
      selectedTime: string | null,
      fieldIndex: number,
      field: 'pickupTime' | 'dropoffTime'
    ) => (
      <Box sx={styles.searchInputContainer}>
        <Typography style={styles.searchInputLabel}>{label}</Typography>
        <div
          data-test-id={ids.searchTimeField}
          style={{
            ...styles.searchDropDown,
            borderColor: focusedIndex === fieldIndex ? theme.palette.accent.main : theme.palette.borders.main,
          }}
          ref={refsArray[fieldIndex] as React.RefObject<HTMLInputElement>}
        >
          <div
            data-test-id={ids.searchTimeFieldBtn}
            style={{ ...styles.searchDropDownContainer, ...styles.searchDropDownCalendarContainer }}
            onClick={() => handleFocusClick(fieldIndex)}
          >
            <img
              style={styles.searchDropDownIconStart}
              src={require('../assets/time.svg')}
              alt="time"
            />
            <Box sx={styles.searchInputTextContainer}>
              <Typography style={styles.searchInputText}>
                {selectedTime || label}
              </Typography>
            </Box>
          </div>
          {focusedIndex === fieldIndex && renderTimeSheetList(selectedTime || '', field)}
        </div>
      </Box>
    );

    const renderPickupTime = () => {
      return renderTimeField('Pickup time', pickupTime, 3, 'pickupTime');
    };

    const renderDropoffTime = () => {
      return renderTimeField('Drop off time', dropoffTime, 4, 'dropoffTime');
    };

    const renderSectionTitle = (title: string) => (
      <Typography style={styles.filterTitle}>
        {title}
      </Typography>
    );

    const renderInputField = (label: string, name: string, value: string | number, placeholder: string, dataTestId: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void, onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void) => (
      <Box sx={styles.filterSectInput}>
        <Typography style={styles.filterInputLabel}>
          {label}
        </Typography>
        <Box sx={{
          ...styles.filterInputContainer,
          border: `1px solid ${theme.palette.borders.main}`,
        }}>
          <input
            data-test-id={dataTestId}
            name={name}
            type="text"
            style={styles.filterInput}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
          <img
            style={styles.filterInputIcon}
            src={require('../assets/euro.svg')}
            alt="euro"
          />
        </Box>
      </Box>
    );

    const renderCheckboxList = (
      items: string[],
      selectedItems: string[],
      type: 'vehicleType' | 'fuleType' | 'transmissionType' | 'purposeUsage' | 'specialFeature' | 'comfortEnt',
      dataTestId: string,
    ) => (
      items.map((item: string, index) => {
        const isSelected = selectedItems.includes(item);
        const isVisible = isSelected ? 1 : 0;
        return (
          <Box key={item} style={styles.filterSectOpt}>
            <Typography style={styles.filterSectOptTitle}>
              {item}
            </Typography>
            <span style={{
              ...styles.filterSectCheckbox,
              borderColor: isSelected ? theme.palette.accent.main : theme.palette.texts.dark,
            }}>
              <span style={{ ...styles.filterSectCheckboxIcons, opacity: isVisible }}>
                <img style={styles.filterSectCheckboxIcon} src={require('../assets/checked.svg')} alt="checked" />
              </span>
              <input
                data-test-id={`${dataTestId}-${index + 1}`}
                type="checkbox"
                style={styles.filterSectToggler}
                checked={isSelected}
                onChange={() => handleChangeCheckbox(type, item)}
              />
            </span>
          </Box>
        );
      })
    );

    const renderSittingCapacities = (selectedOption: string, dataTestId: string) => {
      return (
        ['2 Person', '4 Person', '5 Person', '7 Person', 'More than 7 person'].map((option: string, index: number) => {
          const isSelected = selectedOption === option;
          const isVisible = isSelected ? 1 : 0;
          return (
            <Box data-test-id={`${dataTestId}-${index + 1}`} key={option} style={styles.filterSectOpt}>
              <Typography style={styles.filterSectOptTitle}>
                {option}
              </Typography>
              <Box sx={{
                ...styles.filterSectRadioContainer,
                borderColor: isSelected ? theme.palette.accent.main : theme.palette.texts.dark,
              }}>
                <span style={{ ...styles.filterSectRadio, opacity: isVisible, }} />
                <input
                  type="radio"
                  style={styles.fitlerSectRadioInput}
                  value={option}
                  checked={isSelected}
                  onChange={({ target: { value } }) => handleChangeSittingCapacity(value)}
                />
              </Box>
            </Box>
          );
        })
      );
    };

    const renderFilterComponent = () => {
      return (
        <Box sx={styles.filterContainer}>
          <Box style={styles.filterHeader}>
            <Typography style={styles.filterHeading} variant="h6">Filter</Typography>
            <Button data-test-id={ids.filterClearBtn} style={styles.filterClearButton} onClick={handleClear}>
              Clear All
            </Button>
          </Box>

          <Box sx={styles.filterContent}>
            <Box sx={styles.filterSect}>
              <Box sx={styles.filterSectList}>
                {renderSectionTitle('Rent per day')}
                <Box style={styles.filterSectPricing}>
                  {renderInputField('Min. Price', 'minPrice', minPrice, '0.00', ids.filterPriceMin, handleChangePrice, handlePriceBlur)}
                  {renderInputField('Max Price', 'maxPrice', maxPrice, '0.00', ids.filterPriceMax, handleChangePrice, handlePriceBlur)}
                </Box>
              </Box>
            </Box>

            <Box sx={styles.filterSect}>
              <Box sx={styles.filterSectList}>
                {renderSectionTitle('Sitting Capacity (Incl. Rider)')}
                {renderSittingCapacities(sittingCapacity, ids.sittingCapacity)}
              </Box>
            </Box>

            <Box sx={styles.filterSect}>
              <Box sx={styles.filterSectList}>
                {renderSectionTitle('Vehicle Type')}
                {renderCheckboxList(['Compact', 'Sedan', 'SUV', 'Camper', 'Convertible', 'Luxury'], vehicleType, 'vehicleType', ids.vehicleType)}
                <Typography style={styles.filterShowAll}>Show All</Typography>
              </Box>
            </Box>

            <Box sx={styles.filterSect}>
              <Box sx={styles.filterSectList}>
                {renderSectionTitle('Fule Type')}
                {renderCheckboxList(['Petrol', 'Diesel', 'Hybrid', 'Electric'], fuleType, 'fuleType', ids.fuleType)}
              </Box>
            </Box>

            <Box sx={styles.filterSect}>
              <Box sx={styles.filterSectList}>
                {renderSectionTitle('Transmission Type')}
                {renderCheckboxList(['Automatic', 'Manual'], transmissionType, 'transmissionType', ids.transmission)}
              </Box>
            </Box>

            <Box sx={styles.filterSect}>
              <Box sx={styles.filterSectList}>
                {renderSectionTitle('Purpose of usage')}
                {renderCheckboxList(['City driving', 'Adventure touring', 'Business travel', 'Family travel'], purposeUsage, 'purposeUsage', ids.purposeUsage)}
                <Typography style={styles.filterShowAll}>Show All</Typography>
              </Box>
            </Box>

            <Box sx={styles.filterSect}>
              <Box sx={styles.filterSectList}>
                {renderSectionTitle('Special Feature')}
                {renderCheckboxList(['AWD', 'Sports', 'Eco-friendly'], specialFeature, 'specialFeature', ids.specialFeature)}
              </Box>
            </Box>

            <Box sx={styles.filterSect}>
              <Box sx={styles.filterSectList}>
                {renderSectionTitle('Comfort & Entertainment')}
                {renderCheckboxList(['Air conditioning', 'Stability control', 'GPS', 'Heated seats', 'Bluetooth audio', 'Roof rack'], comfortEnt, 'comfortEnt', ids.comfortEnt)}
                <Typography style={styles.filterShowAll}>Show All</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    };

    const renderToolbarButton = (label: string, iconSrc: string, onClick: () => void, dataTestId?: string) => (
      <button
        data-test-id={dataTestId}
        style={styles.toolbarButtonContainer}
        onClick={onClick}
      >
        <img src={require(`../assets/${iconSrc}.svg`)} alt={label} />
        <div style={styles.toolbarButton}>{label}</div>
      </button>
    );

    const CategoryList = () => (
      <div data-test-id={ids.sortList} style={styles.categoryList}>
        {categories.map((category: string, index) => {
          const isSelected = selectedCategory === category;
          const currentCategory = isSelected ? null : category;
          return (
            <Button
              data-test-id={`${ids.sortOption}-${index + 1}`}
              key={category}
              style={{
                ...styles.categoryItem,
                ...(isSelected && styles.categoryItemSelected),
              }}
              onMouseDown={() => handleSelectCategory(currentCategory)}
            >
              {category}
            </Button>
          );
        })}
      </div>
    );

    const renderCategoryList = () => focusedIndex === 5 ? CategoryList() : null;

    const renderToolbar = () => (
      <Box sx={styles.toolbarContainer}>
        {renderToolbarButton('Map view', 'map', handleToggleMap, ids.mapToggleButton)}

        <button
          data-test-id={ids.sortBtn}
          ref={refsArray[5] as React.RefObject<HTMLButtonElement>}
          style={styles.toolbarButtonContainer}
          onMouseDown={() => handleFocusClick(5)}
        >
          <img style={styles.toolbarButtonIcon} src={require('../assets/sort.svg')} alt="sort" />
          <div style={styles.toolbarButton}>
            Sort
          </div>
          {renderCategoryList()}
        </button>
      </Box>
    );

    const renderCarItem = (
      carImage: string,
      carName: string,
      carLocation: string,
      currentPrice: string,
      originalPrice: string,
      currentItem: number,
    ) => (
      <Box sx={styles.recomCarItem}>
        <img src={require(`../assets/${carImage}`)} alt={carName} />
        <Box sx={styles.recomCarContent}>
          <Box sx={styles.recomCarDetails}>
            <Typography style={styles.recomCarName}>{carName}</Typography>
            <Typography style={styles.recomCarLocation}>{carLocation}</Typography>
          </Box>
          <Box sx={styles.recomCarPricing}>
            <Typography style={styles.recomCarPriceLabel}>Starting @</Typography>
            <Box sx={styles.recomCarPriceContainer}>
              <Typography style={styles.recomCarCurrentPrice}>{currentPrice}</Typography>
              <Box sx={styles.recomCarDiscountContainer}>
                <Typography style={styles.recomCarOriginalPrice}>{originalPrice}</Typography>
                <Box sx={styles.recomCarDiscount} />
              </Box>
            </Box>
          </Box>
          <Button data-test-id={`${ids.recommCarCardBtn}-${currentItem}`} style={styles.recomSelectButton} onClick={() => { }}>
            Select
          </Button>
        </Box>
      </Box>
    );

    const renderRecommendedCars = () => (
      <Box sx={styles.recomCarsContainer}>
        <Typography style={styles.recomCarsTitle} variant="h4">Recommended Cars</Typography>
        <Box sx={styles.recomCarsListContainer}>
          <Box sx={styles.recomCarsList}>
            {renderCarItem('recom-cars-1.svg', 'Volkswagen Golf 2021', 'ABC Motors, Gatwick, London', '€ 89/day', '€ 189/day', 1)}
            {renderCarItem('recom-cars-2.svg', 'Lamborghini Huracan', 'ABC Motors, Gatwick, London', '€ 89/day', '€ 189/day', 2)}
            {renderCarItem('recom-cars-1.svg', 'Volkswagen Golf 2021', 'ABC Motors, Gatwick, London', '€ 89/day', '€ 189/day', 3)}
            {renderCarItem('recom-cars-2.svg', 'Lamborghini Huracan', 'ABC Motors, Gatwick, London', '€ 89/day', '€ 189/day', 4)}
          </Box>
        </Box>
      </Box>
    );

    const renderCar = (
      carImage: string,
      carName: string,
      carLocation: string,
      currentPrice: string,
      currentItem: number,
    ) => (
      <Box sx={styles.allCarItem}>
        <Box sx={styles.allCarItemContent}>
          <img src={require(`../assets/${carImage}`)} alt={carName} />
          <Box sx={styles.allCarDetails}>
            <Box sx={styles.allCarInfo}>
              <Typography style={styles.allCarName}>{carName}</Typography>
              <Box style={styles.allCarLocationContainer}>
                <img style={styles.allCarLocationIcon} src={require('../assets/location.svg')} alt="location" />
                <Typography style={styles.allCarLocation}>{carLocation}</Typography>
              </Box>
            </Box>

            <Box style={styles.carDetailsContainer}>
              <Box style={styles.carDetailItem}>
                <img src={require('../assets/passenger.svg')} alt="passenger" />
                <Typography>4</Typography>
              </Box>

              <Box style={styles.carDetailItem}>
                <img src={require('../assets/car.svg')} alt="car" />
                <Typography>Compact</Typography>
              </Box>

              <Box style={styles.carDetailItem}>
                <img src={require('../assets/transmission.svg')} alt="transmission" />
                <Typography>M</Typography>
              </Box>

              <Box style={styles.carDetailItemNoBorder}>
                <img src={require('../assets/air-conditioning.svg')} alt="air-conditioning" />
                <Typography>A/C</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={styles.carPricingContainer}>
          <Box sx={styles.allCarPricing}>
            <Typography style={styles.allCarPriceLabel}>Starting @</Typography>
            <Typography style={styles.allCarCurrentPrice}>{currentPrice}</Typography>
          </Box>
          <Button data-test-id={`${ids.carCardBtn}-${currentItem}`} style={styles.allCarSelectButton} onClick={() => { }}>
            Select
          </Button>
        </Box>
      </Box>
    );

    const renderAllCars = () => (
      <Box sx={styles.allCarsContainer}>
        <Typography style={styles.allCarsTitle} variant="h4">All Cars</Typography>
        <Box sx={styles.allCarsList}>
          {renderCar('all-cars-1.svg', 'Volkswagen Golf 2021', 'ABC Motors, Gatwick, London, UK', '€ 89/day', 1)}
          {renderCar('all-cars-2.svg', 'Lamborghini Huracan', 'ABC Motors, Gatwick, London, UK', '€ 89/day', 2)}
          {renderCar('all-cars-1.svg', 'Volkswagen Golf 2021', 'ABC Motors, Gatwick, London, UK', '€ 89/day', 3)}
          {renderCar('all-cars-2.svg', 'Lamborghini Huracan', 'ABC Motors, Gatwick, London, UK', '€ 89/day', 4)}
          {renderCar('all-cars-1.svg', 'Volkswagen Golf 2021', 'ABC Motors, Gatwick, London, UK', '€ 89/day', 5)}
        </Box>
      </Box>
    );

    const renderCarCard = (car: Car, isSelected: boolean) => (
      <Box sx={{ ...styles.carCard, ...(isSelected && styles.carCardSelected) }}>
        <img style={styles.carImage} src={require(`../assets/all-cars-1.svg`)} alt={car.name} />
        <Box sx={styles.carCardContent}>
          <Box sx={styles.carCardHeader}>
            <Box sx={styles.carInfo}>
              <Typography style={styles.carName}>{car.name}</Typography>
              <Typography style={styles.carLocation}>{car.location}</Typography>
            </Box>
            <Box sx={styles.carPricing}>
              <Typography style={styles.carPriceLabel}>Starting @</Typography>
              <Typography style={styles.carPrice}>€ {car.price}</Typography>
              <Typography style={styles.carPriceText}>per day</Typography>
            </Box>
          </Box>
          <Box sx={styles.carCardFooter}>
            <Typography style={styles.carTaxes}>€ {car.taxes} taxes and charges</Typography>
            <Box style={styles.carPassengers}>
              <img style={styles.carPassengersIcon} src={require('../assets/passenger.svg')} alt="guests" />
              <Typography style={styles.carPassengersCount}>{car.guestsCount}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );

    const CarMapPoint = (id: number, coord: { lat: number, lng: number }) => {
      return (
        <Marker
          key={`selected-car-${id}`}
          position={coord}
          icon={require('../assets/map-point.svg')}
          zIndex={101}
        />
      );
    };

    const renderCarMapPoint = (
      id: number,
      coord: { lat: number, lng: number },
      isSelected: boolean,
    ) => isSelected ? CarMapPoint(id, coord) : null;

    const renderCarMap = () => (
      <Box sx={{ ...styles.carMap, ...(!isMapOpened && styles.carMapDisabled) }}>
        <Box sx={styles.carList}>
          {cars.map((car) => renderCarCard(car, this.state.selectedCar === car.id))}
        </Box>

        <Box sx={styles.map}>
          <Box sx={styles.mapContainer}>
            <GoogleMap
              center={cars[0].coordinates}
              zoom={16}
              mapContainerStyle={styles.mapView}
              options={{
                disableDefaultUI: true,
                zoomControl: false,
                fullscreenControl: false
              }}
            >
              {cars.map((car) => {
                const isSelected = selectedCar === car.id;
                const markerCardIcon = require(isSelected ? '../assets/map-marker-selected.svg' : '../assets/map-marker.svg');
                const markerCardWidth = isSelected ? 76 : 56;
                const markerCardHeight = isSelected ? 40 : 30;
                const markerCardFontSize = isSelected ? '16px' : '12px';
                const markerCardColor = isSelected ? theme.palette.backgrounds.light : theme.palette.texts.light;
                const markerCardIndex = isSelected ? 100 : 1;
                return (
                  <>
                    <Marker
                      key={car.id}
                      position={{
                        lat: car.coordinates.lat + 0.00040,
                        lng: car.coordinates.lng,
                      }}
                      icon={{
                        url: markerCardIcon,
                        labelOrigin: new google.maps.Point((markerCardWidth) / 2, ((markerCardHeight) / 2) - 3),
                      }}
                      label={{
                        text: `€ ${car.price}`,
                        fontSize: markerCardFontSize,
                        fontWeight: 'bold',
                        color: markerCardColor,
                      }}
                      zIndex={markerCardIndex}
                    />
                    {renderCarMapPoint(car.id, car.coordinates, isSelected)}
                  </>
                );
              })}
            </GoogleMap>
            <Box
              data-test-id={ids.mapCloseButton}
              style={styles.mapCloseButton}
              onClick={handleToggleMap}
            >
              <img style={styles.mapCloseIcon} src={require('../assets/close-selected.svg')} alt="close-map" />
              <Typography style={styles.mapCloseText}>
                Close map view
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );

    return (
      <ThemeProvider theme={theme}>
        <Container style={styles.container}>
          {/* Customizable Area Start */}
          <Box sx={styles.navContainer}>
            <img
              style={styles.navLogo}
              src={require('../assets/logo.svg')}
              alt="logo"
            />
            <Box sx={styles.navList}>
              {renderNavList()}
            </Box>
          </Box>
          <Box sx={styles.rightSideContainer}>
            <Box sx={styles.headerContainer}>
              <Typography style={styles.headerTitle} variant="h2">
                Cars
              </Typography>
              <Box sx={styles.headerIcons}>
                {['notification.svg', 'cart.svg'].map((icon, index) => (
                  <img key={index} src={require(`../assets/${icon}`)} alt={icon.split('.')[0]} />
                ))}
              </Box>
            </Box>
            <Box sx={styles.mainContainer}>
              <Box sx={styles.searchContainer}>
                <Box sx={styles.tripDetailsContainer}>
                  <Typography style={styles.tripDetailsTitle}>
                    Trip Details
                  </Typography>
                  <Box style={styles.roundTripContainer}>
                    <Typography style={styles.roundTripText}>
                      Round Trip
                    </Typography>
                    <IOSSwitch data-test-id={ids.roundTripSwitch} />
                  </Box>
                </Box>
                <Box style={styles.searchFieldsContainer}>
                  {renderAddressSearch()}
                  {renderStartDateCalendar()}
                  {renderPickupTime()}
                  {renderEndDateCalendar()}
                  {renderDropoffTime()}
                  <Button data-test-id={ids.searchBtn} style={styles.searchButton}>
                    Search
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  ...styles.mainContentContainer,
                  ...(isMapOpened && styles.mainContentContainerDisabled),
                }}
              >
                {renderFilterComponent()}
                <Box sx={styles.carGridContainer}>
                  {renderToolbar()}
                  {renderRecommendedCars()}
                  {renderAllCars()}
                </Box>
              </Box>
              {renderCarMap()}
            </Box>
          </Box>
          {/* Customizable End Start */}
        </Container>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
};
// Customizable Area End
