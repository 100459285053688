import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  promptResuilt: any;
  date: string;
  PromptId: string;
  review: string;
  comment: string;
  scoring: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PromptReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPromptReport: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      promptResuilt: {},
      date: "",
      PromptId: "",
      review: "string",
      comment: "",
      scoring: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getPrompt = this.getPrompt.bind(this);

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getPromptReport) {
        this.setState({ promptResuilt: responseJson.data }, () => {
          this.handleChangeData();
        });
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPrompt();
    this.setPromptId();
  }
  async setPromptId() {
    const PromptId = await getStorageData("PromptId");
    this.setState((prev) => ({ ...prev, PromptId: PromptId || "" }));
  }
  handleChangeData = async () => {
    const { promptResuilt } = this.state;
    const dateString = await promptResuilt?.attributes?.created_at;

    if (!dateString) {
      return;
    }

    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = date.toLocaleString("en-US", options);

    this.setState((prevState) => ({ ...prevState, date: formattedDate }));
  };

  getPrompt = async () => {
    const pid = await getStorageData("PromptId");
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPromptReport = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_prompt_manager/prompt_managers/${pid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
