import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject, createRef } from 'react';
import { isAfter, isBefore, isEqual, isToday } from "date-fns";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  searchAddress: string;
  suggestions: string[];
  locations: string[];
  timeOptions: string[];
  refsArray: RefObject<HTMLDivElement | HTMLButtonElement>[];
  focusedIndex: number | null;
  currentDate: Date;
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  pickupTime: string,
  dropoffTime: string,
  minPrice: number,
  maxPrice: number,
  vehicleType: string[],
  fuleType: string[],
  transmissionType: string[],
  purposeUsage: string[],
  specialFeature: string[],
  comfortEnt: string[],
  sittingCapacity: string,
  selectedCategory: string | null,
  isMapOpened: boolean,
  selectedCar: number | null,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EndUserCarAdvancedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      searchAddress: "",
      suggestions: [],
      locations: [
        '10 Downing Street London, UK',
        '10 Downing Street New York, NY, USA',
        '10 Downing Street Glenfield, Auckland, New Zealand',
      ],
      timeOptions: [
        '08:00 AM', '09:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '01:00 PM', '02:00 PM', '03:00 PM', '04:00 PM', '05:00 PM', '06:00 PM', '07:00 PM', '08:00 PM', '09:00 PM', '10:00 PM'
      ],
      refsArray: [createRef(), createRef(), createRef(), createRef(), createRef(), createRef()],
      focusedIndex: null,
      currentDate: new Date(),
      selectedStartDate: null,
      selectedEndDate: null,
      pickupTime: '',
      dropoffTime: '',
      minPrice: 0,
      maxPrice: 0,
      vehicleType: [],
      fuleType: [],
      transmissionType: [],
      purposeUsage: [],
      specialFeature: [],
      comfortEnt: [],
      sittingCapacity: '2 Person',
      selectedCategory: 'Category',
      isMapOpened: false,
      selectedCar: 1,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    document.addEventListener('mousedown', this.handleClickOutside);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.handleSessionResponse(message);
      return;
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleAPIResponse(message);
    }
    // Customizable Area End
  }

  private handleSessionResponse(message: Message) {
    const token = message.getData(getName(MessageEnum.SessionResponseToken));
    runEngine.debugLog("TOKEN", token);
    this.setState({ token });
    this.getAdvancedSearchList(token);
  }

  private handleAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (responseJson?.accounts) {
      this.processAccounts(responseJson.accounts);
      return;
    }

    if (responseJson?.errors && apiRequestCallId === this.advancedsearchApiCallId) {
      this.showAlert("Alert", "API Error", "");
    }
  }

  private processAccounts(accounts: any) {
    if (typeof accounts === "string") {
      alert(accounts);
    } else {
      this.setState({ advancedsearchList: accounts.data });
    }
  }


  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  highlightMatch(text: string, query: string) {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) => (
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} style={{ fontWeight: 'bold', color: '#FAA81D' }}>{part}</span>
      ) : (
        part
      )
    ));
  }

  handleAddressClear = () => {
    this.setState((prev) => ({ ...prev, searchAddress: "", suggestions: [], focusedIndex: null }));
  }

  handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    this.setState({ searchAddress: query });

    if (query.length > 0) {
      const filteredSuggestions = this.state.locations.filter((location) =>
        location.toLowerCase().includes(query.toLowerCase())
      );
      this.setState({ suggestions: filteredSuggestions });
    } else {
      this.setState({ suggestions: [] });
    }
  };

  handleSelectSuggestion = (suggestion: string) => {
    this.setState({ searchAddress: suggestion, suggestions: [] });
  };

  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  };

  handleStartDateClick = (date: Date) => {
    const { selectedStartDate } = this.state;
    if (selectedStartDate && isEqual(date, selectedStartDate)) {
      this.setState({ selectedStartDate: null, selectedEndDate: null, pickupTime: '', dropoffTime: '' });
    } else {
      this.setState({ selectedStartDate: date }, this.syncDropoffTime);
    }
  };

  handleEndDateClick = (date: Date) => {
    const { selectedEndDate } = this.state;
    if (selectedEndDate && isEqual(date, selectedEndDate)) {
      this.setState({ selectedEndDate: null, dropoffTime: '' });
    } else {
      this.setState({ selectedEndDate: date }, this.syncDropoffTime);
    }
  };

  isStartTimeDisabled = () => {
    return !this.state.selectedStartDate;
  };

  isEndTimeDisabled = (time: string) => {
    const timeOptions = this.state.timeOptions;
    const startTimeIndex = timeOptions.indexOf(this.state.pickupTime || '');
    const currentTimeIndex = timeOptions.indexOf(time);

    if (!this.state.pickupTime) return true;

    if (!this.state.selectedEndDate) return true;

    if (this.state.selectedStartDate && this.state.selectedEndDate && isEqual(this.state.selectedStartDate, this.state.selectedEndDate)) {
      return currentTimeIndex <= startTimeIndex;
    }

    return false;
  };

  syncDropoffTime = () => {
    const { pickupTime, dropoffTime, selectedStartDate, selectedEndDate } = this.state;
    const timeOptions = this.state.timeOptions;
    const pickupTimeIndex = timeOptions.indexOf(pickupTime);
    const lastTimeIndex = timeOptions.length - 1;

    if (!pickupTime) {
      this.setState({ dropoffTime: '' });
      return;
    }

    if (selectedStartDate && selectedEndDate && isEqual(selectedStartDate, selectedEndDate)) {
      const dropoffTimeIndex = timeOptions.indexOf(dropoffTime);

      if (pickupTimeIndex >= dropoffTimeIndex) {
        if (pickupTimeIndex === lastTimeIndex) {
          this.setState({ dropoffTime: '' });
        } else {
          this.setState({ dropoffTime: timeOptions[pickupTimeIndex + 1] });
        }
      }
    }

    if (selectedEndDate && selectedStartDate && isBefore(selectedEndDate, selectedStartDate)) {
      this.setState({ dropoffTime: '' });
    }
  };

  handleTimeSelect = (field: 'pickupTime' | 'dropoffTime', time: string) => {
    this.setState((prevState) => {
      const currentSelectedTime = field === 'pickupTime' ? prevState.pickupTime : prevState.dropoffTime;

      const newTime = currentSelectedTime === time ? '' : time;

      if (field === 'pickupTime') {
        return { ...prevState, pickupTime: newTime, };
      } else {
        return { ...prevState, dropoffTime: newTime };
      }
    }, this.syncDropoffTime);
    this.handleFocus(null);
  };

  isDateDisabled = (date: Date) => {
    const today = new Date();
    return (
      isBefore(date, today) &&
      !isToday(date) &&
      (date.getFullYear() < today.getFullYear() ||
        (date.getFullYear() === today.getFullYear() && date.getMonth() < today.getMonth()) ||
        (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() < today.getDate()))
    );
  };

  isDateDisabledStartCalendar = (date: Date) => {
    const today = new Date();
    const { selectedEndDate } = this.state;

    return (
      (selectedEndDate && isAfter(date, selectedEndDate)) ||
      (isBefore(date, today) && !isToday(date))
    );
  };

  isDateDisabledEndCalendar = (date: Date) => {
    const { selectedStartDate } = this.state;
    const today = new Date();

    return (
      (selectedStartDate && isBefore(date, selectedStartDate)) ||
      (isBefore(date, today) && !isToday(date))
    );
  };

  isCurrentDay = (date: Date) => isToday(date);


  handleFocusClick = (currentIndex: number) => {
    this.setState({ focusedIndex: this.state.focusedIndex === currentIndex ? null : currentIndex });
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.state.focusedIndex !== null) {
      const currentComponent = this.state.refsArray[this.state.focusedIndex];
      if (currentComponent && currentComponent.current && !currentComponent.current.contains(event.target as Node)) {

        this.setState({ focusedIndex: null });
      }
    }
  };

  handleFocus = (index: number | null) => {
    this.setState({ focusedIndex: index });
  };

  handleBlur = () => {
    this.setState({ focusedIndex: null });
  }

  handleChangeMonth = (newDate: Date) => {
    this.setState({ currentDate: newDate });
  }

  cleanCurrencyInput = (value: string): string => {
    return value.replace(/[^\d.]/g, '');
  };

  formatCurrency = (value: string): string => {
    if (!value || isNaN(Number(value))) return '0.00';
    const [integerPart, decimalPart] = value.split('.');
    const formattedInteger = Number(integerPart).toLocaleString('en-US');
    return `${formattedInteger}.${(decimalPart || '00').padEnd(2, '0')}`;
  };

  handlePriceBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const cleanedValue = this.cleanCurrencyInput(value);
    const formattedValue = this.formatCurrency(cleanedValue);
    this.setState((prev) => ({ ...prev, [name]: formattedValue }));
  };

  handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const cleanedValue = this.cleanCurrencyInput(value);
    this.setState((prev) => ({ ...prev, [name]: cleanedValue }));
  };

  formatDateDayMonth = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthsShort[date.getMonth()];

    return `${day} ${month}`;
  };


  formatDateFullMonth = (date: Date): string => {
    const monthsFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = monthsFull[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${year}`;
  };

  handleChangeCheckbox = (
    key: keyof Pick<S, 'vehicleType' | 'fuleType' | 'transmissionType' | 'purposeUsage' | 'specialFeature' | 'comfortEnt'>,
    value: string,
  ) => {
    this.setState((prev) => {
      if (prev[key].includes(value)) {
        return { ...prev, [key]: prev[key].filter((item: string) => item !== value) };
      } else {
        return { ...prev, [key]: [...prev[key], value] };
      }
    });
  };

  handleChangeSittingCapacity = (value: string) => {
    this.setState((prev) => ({ ...prev, sittingCapacity: value }));
  };

  handleClear = () => {
    this.setState((prev) => ({
      ...prev,
      minPrice: 0,
      maxPrice: 0,
      vehicleType: [],
      sittingCapacity: '2 Person',
      fuleType: [],
      transmissionType: [],
      purposeUsage: [],
      specialFeature: [],
      comfortEnt: [],
    }));
  };

  handleSelectCategory = (category: string | null) => {
    this.setState((prev) => ({ ...prev, selectedCategory: category, focusedIndex: null }));
  };

  handleToggleMap = () => {
    this.setState((prev) => ({ ...prev, isMapOpened: !prev.isMapOpened }));
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
