import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject, createRef } from 'react';
import { isAfter, isBefore, isEqual, isToday } from "date-fns";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  locationValue: string;
  suggestions: string[];
  locations: string[];
  refsArray: RefObject<HTMLDivElement | HTMLButtonElement>[];
  focusedIndex: number | null;
  currentDate: Date;
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  adults: number
  children: number,
  rooms: number,
  tempAdults: number,
  tempChildren: number,
  filterLocation: string,
  tempRooms: number,
  minPrice: number,
  maxPrice: number,
  starRatings: string[],
  roomTypes: string[],
  amenities: string[],
  themeStyle: string[],
  mealOption: string,
  selectedCategory: string | null,
  isMapOpened: boolean,
  selectedHotel: number | null,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EndUserHotelAdvancedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      locationValue: "",
      suggestions: [],
      locations: [
        '10 Downing Street London, UK',
        '10 Downing Street New York, NY, USA',
        '10 Downing Street Glenfield, Auckland, New Zealand',
      ],
      refsArray: [createRef(), createRef(), createRef(), createRef()],
      focusedIndex: null,
      currentDate: new Date(),
      selectedStartDate: null,
      selectedEndDate: null,
      adults: 0,
      children: 0,
      rooms: 0,
      tempAdults: 0,
      tempChildren: 0,
      tempRooms: 0,
      minPrice: 0,
      maxPrice: 0,
      starRatings: [],
      filterLocation: '',
      roomTypes: [],
      amenities: [],
      themeStyle: [],
      mealOption: 'Breakfast included',
      selectedCategory: 'Category',
      isMapOpened: false,
      selectedHotel: 1,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    document.addEventListener('mousedown', this.handleClickOutside);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getAdvancedSearchList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      // alert(JSON.stringify(responseJson))
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      // alert(JSON.stringify(responseJson))
      // if (responseJson && responseJson.data.d) {
      //   alert("FF")
      // } else {
      //   alert(JSON.stringify(responseJson.data.accounts.data))
      // }

      if (responseJson && responseJson.accounts) {
        if (typeof responseJson.accounts === "string") {
          alert(responseJson.accounts);
        } else {
          this.setState({ advancedsearchList: responseJson.accounts.data });
        }
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          if (apiRequestCallId === this.advancedsearchApiCallId) {
            this.showAlert("Alert", "API Error", "");
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  highlightMatch(text: string, query: string) {
    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) => (
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} style={{ fontWeight: 'bold', color: '#FAA81D' }}>{part}</span>
      ) : (
        part
      )
    ));
  }

  handleLocationClear = () => {
    this.setState((prev) => ({ ...prev, locationValue: "", suggestions: [], focusedIndex: null }));
  }

  handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    this.setState({ locationValue: query });

    if (query.length > 0) {
      const filteredSuggestions = this.state.locations.filter((location) =>
        location.toLowerCase().includes(query.toLowerCase())
      );
      this.setState({ suggestions: filteredSuggestions });
    } else {
      this.setState({ suggestions: [] });
    }
  };

  handleSelectSuggestion = (suggestion: string) => {
    this.setState({ locationValue: suggestion, suggestions: [] });
  };

  async componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  };

  isInRange = (date: Date) => this.state.selectedStartDate && this.state.selectedEndDate && date >= this.state.selectedStartDate && date <= this.state.selectedEndDate;
  isStart = (date: Date): boolean => !!this.state.selectedStartDate && isEqual(date, this.state.selectedStartDate);
  isEnd = (date: Date): boolean => !!this.state.selectedEndDate && isEqual(date, this.state.selectedEndDate);
  isDateDisabled = (date: Date) => {
    const today = new Date();
    const isPastDate =
      isBefore(date, today) &&
      !isToday(date) &&
      (date.getFullYear() < today.getFullYear() ||
        (date.getFullYear() === today.getFullYear() && date.getMonth() < today.getMonth()) ||
        (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() < today.getDate()));
    return isPastDate;
  };
  isCurrentDay = (date: Date) => isToday(date);

  handleGuestsDropdownToggle = () => {
    this.setState((prev) => {
      if (prev.focusedIndex === 2) {
        return {
          ...prev,
          focusedIndex: null,
          tempAdults: prev.adults,
          tempChildren: prev.children,
          tempRooms: prev.rooms,
        };
      } else {
        return { ...prev, focusedIndex: 2 };
      }
    });
  };
  
  handleFocusClick = (currentIndex: number) => {
    if (currentIndex === 2) {
      this.handleGuestsDropdownToggle();
    } else {
      this.setState({ focusedIndex: this.state.focusedIndex === currentIndex ? null : currentIndex });
    }
  };

  handleGuestsDropdownClose = () => {
    this.setState((prev) => ({
      tempAdults: prev.adults,
      tempChildren: prev.children,
      tempRooms: prev.rooms,
    }));
  };

  handleClickOutside = (event: MouseEvent) => {
    if (this.state.focusedIndex !== null) {
      const currentComponent = this.state.refsArray[this.state.focusedIndex];
      if (currentComponent && currentComponent.current && !currentComponent.current.contains(event.target as Node)) {
        if (this.state.focusedIndex === 2) {
          this.handleGuestsDropdownClose();
        }
        
        this.setState({ focusedIndex: null });
      }
    }
  };

  handleFocus = (index: number | null) => {
    this.setState({ focusedIndex: index });
  };

  handleBlur = () => {
    this.setState({ focusedIndex: null });
  }

  handleChangeMonth = (newDate: Date) => {
    this.setState({ currentDate: newDate });
  }

  handleDateClick = (date: Date) => {
    if (!this.isDateDisabled(date)) {
      const { selectedStartDate, selectedEndDate } = this.state;

      if (!selectedStartDate || (selectedStartDate && selectedEndDate)) {

        this.setState({ selectedStartDate: date, selectedEndDate: null });
      } else if (selectedStartDate && !selectedEndDate) {
        if (isAfter(date, selectedStartDate)) {
          this.setState({ selectedEndDate: date });
        } else {
          this.setState({ selectedStartDate: null, selectedEndDate: null });
        }
      }
    }
  }

  handleIncrement = (key: keyof Pick<S, 'adults' | 'children' | 'rooms' | 'tempAdults' | 'tempChildren' | 'tempRooms'>) => {
    this.setState((prev) => ({ ...prev, [key]: prev[key] + 1 }));
  };

  handleDecrement = (key: keyof Pick<S, 'adults' | 'children' | 'rooms' | 'tempAdults' | 'tempChildren' | 'tempRooms'>, minValue = 0) => {
    this.setState((prev) => ({
      ...prev,
      [key]: prev[key] > minValue ? prev[key] - 1 : minValue,
    }));
  };

  handleDone = () => {
    this.setState((prev) => ({
      ...prev,
      adults: prev.tempAdults,
      children: prev.tempChildren,
      rooms: prev.tempRooms,
      focusedIndex: null,
    }));
  };

  handleChangeFilterLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prev) => ({ ...prev, filterLocation: event.target.value }));
  };

  cleanCurrencyInput = (value: string): string => {
    return value.replace(/[^\d.]/g, '');
  };
  
  formatCurrency = (value: string): string => {
    if (!value || isNaN(Number(value))) return '0.00';
    const [integerPart, decimalPart] = value.split('.');
    const formattedInteger = Number(integerPart).toLocaleString('en-US');
    return `${formattedInteger}.${(decimalPart || '00').padEnd(2, '0')}`;
  };
  
  handlePriceBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const cleanedValue = this.cleanCurrencyInput(value);
    const formattedValue = this.formatCurrency(cleanedValue);
    this.setState((prev) => ({ ...prev, [name]: formattedValue }));
  };
  
  handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const cleanedValue = this.cleanCurrencyInput(value);
    this.setState((prev) => ({ ...prev, [name]: cleanedValue }));
  };
  

  formatDate = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  formatDateFullMonth = (date: Date): string => {
    const monthsFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = monthsFull[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${year}`;
  };

  handleChangeCheckbox = (key: keyof Pick<S, 'starRatings' | 'roomTypes' | 'amenities' | 'themeStyle'>, value: string) => {
    this.setState((prev) => {
      if (prev[key].includes(value)) {
        return { ...prev, [key]: prev[key].filter((item: string) => item !== value) };
      } else {
        return { ...prev, [key]: [...prev[key], value] };
      }
    });
  };

  handleChangeMealOption = (value: string) => {
    this.setState((prev) => ({ ...prev, mealOption: value }));
  };

  handleClear = () => {
    this.setState((prev) => ({
      ...prev,
      filterLocation: '',
      minPrice: 0,
      maxPrice: 0,
      roomTypes: [],
      starRatings: [],
      amenities: [],
      mealOption: 'Breakfast included',
      themeStyle: [],
    }));
  };

  handleSelectCategory = (category: string | null) => {
    this.setState((prev) => ({ ...prev, selectedCategory: category, focusedIndex: null }));
  };

  handleToggleMap = () => {
    this.setState((prev) => ({ ...prev, isMapOpened: !prev.isMapOpened }));
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
