import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showPreview: boolean;
  llmModel: string;
  prefix: string;
  question: string;
  suffix: string;
  answer: string;
  error: string;
  errorField: boolean;
  selectErrorField: boolean;
  review: string;
  comment: string;
  scoring: string;
  promptId: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PromptReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendPromptData: string = "";
  postCheck: any = {};
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showPreview: false,
      llmModel: "",
      prefix: "",
      question: "",
      suffix: "",
      answer: "",
      error: "",
      errorField: false,
      selectErrorField: false,
      review: "",
      comment: "",
      scoring: "",
      promptId: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.sendPrompt = this.sendPrompt.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false });

      if (apiRequestCallId === this.sendPromptData) {
        if (!this.state.errorField)
          this.props.navigation.navigate("PromptReport");
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setPreviewStates();
  }
  async setPreviewStates() {
    const promptId = await getStorageData("PromptId");
    const question = await getStorageData("question");
    const llmModel = await getStorageData("llmModel");
    const suffix = await getStorageData("suffix");
    const prefix = await getStorageData("prefix");
    const answer = await getStorageData("PromptAnswer");

    this.setState(
      {
        showPreview: !!(llmModel || prefix || suffix || question),
        llmModel: llmModel || "",
        prefix: prefix || "",
        question: question || "",
        suffix: suffix || "",
        answer: answer || "",
        promptId: promptId || "",
      },
      () => {}
    );
  }
  handleSelectChange = (event: any) => {
    this.setState({ scoring: event.target.value });
  };

  handleValidation1 = () => {
    return new Promise<void>((resolve) => {
      const { question, llmModel } = this.state;
      const { review, scoring } = this.state;

      let errorField = false;
      let selectErrorField = false;

      if (review.trim() === "") {
        selectErrorField = true;
      }

      if (scoring.trim() === "") {
        errorField = true;
      }

      this.setState(
        {
          errorField,
          selectErrorField,
          error: errorField ? "This field is required" : "",
        },
        resolve
      );
    });
  };

  handleSubmit = async () => {
    await this.handleValidation1();
    this.sendPrompt();
  };

  handleCommentChange = (event: any) => {
    this.setState({ comment: event.target.value });
  };

  sendPrompt = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const data = {
      prompt_review: {
        scoring: this.state.scoring,
        review: this.state.review,
        comment: this.state.comment,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendPromptData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_prompt_manager/prompt_managers/${this.state.promptId}/create_review`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
