import React from "react";
// Customizable Area Start
import { Box, Typography, Button } from "@material-ui/core";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TodayIcon from "@material-ui/icons/Today";
import CloseIcon from '@material-ui/icons/Close';

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { background, filterimg, carImg } from "./assets";
import MoreVertIcon from '@material-ui/icons/MoreVert';
// Customizable Area End

import CarsListController, { Props } from "./CarsListController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const styles = {
  tabContainer: {
    width: "100%",

    margin: "0 auto",
  },
  tabNav: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    display: "flex",
  },
  tabNavItem: (isActive: any) => ({
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "5px ",
    backgroundColor: isActive ? "#FEFCF9" : "transparent",
    color: isActive ? "#FAA81D" : "black",
    border: isActive ? "1px solid #DEE3EA" : "none",
    fontWeight: isActive ? "bold" : "normal",
    transition: "background-color 0.3s",
  }),
  tabContent: {
    display: "none",
  },
};
const carData = [
  {
    type: 'Compact',
    name: 'Volkswagen Golf 2021',
    number: 'AB23 CDE',
    priceRange: '€72-92',
    category: 'Compact',
    status: 'Rented',
    image: carImg, 
  },
  {
    type: 'Compact',
    name: 'Volkswagen Golf 2021',
    number: 'AB23 CDE',
    priceRange: '€72-92',
    category: 'Compact',
    status: 'Rented',
    image: carImg, 
  },
  {
    type: 'Luxury',
    name: 'Volkswagen Golf 2021',
    number: 'AB23 CDE',
    priceRange: '€72-92',
    category: 'Luxury',
    status: 'Rented',
    image: carImg, 
  },
  {
    type: 'Luxury',
    name: 'Volkswagen Golf 2021',
    number: 'AB23 CDE',
    priceRange: '€72-92',
    category: 'Luxury',
    status: 'Rented',
    image: carImg, 
  },
];
export default class CarsList extends CarsListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box width="100vw" height="auto" sx={webStyle.mainWrapper}>
          <Box sx={webStyle.customBox4}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "92px",
                paddingRight: "48px",
                paddingLeft: "48px",
                paddingBottom: "24px",
                paddingTop: "24px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                    letterSpacing: ".5px",
                    color: "#0F172A",
                    marginRight: "10px",
                  }}
                >
                  Cars
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: "#475569",
                      fontSize: "18px",
                      fontWeight: 700,
                      marginRight: "10px",
                    }}
                  >
                    WEST{" "}
                  </Typography>
                  <Typography
                    style={{
                      color: "#475569",
                      fontSize: "18px",
                      fontWeight: 700,
                      marginRight: "10px",
                      display: "contents",
                    }}
                  >
                    Car Store{" "}
                    <ExpandMoreIcon
                      style={{
                        margin: "0px  !important",
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button
                  style={{
                    width: "125px",
                    height: "44px",
                    backgroundColor: "#FEFCF9",
                    color: "#FAA81D",
                    fontSize: "14px",
                    fontWeight: "bold",
                    letterSpacing: ".5px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    border: "1px solid #faa81d",
                  }}
                >
                  Bulk Update
                </Button>

                <Button
                  style={{
                    width: "125px",
                    height: "44px",
                    marginLeft: "16px",
                    backgroundColor: " #FAA81D",
                    color: "#FEFCF9 ",
                    fontSize: "14px",
                    fontWeight: "bold",
                    letterSpacing: ".5px",
                    borderRadius: "8px",
                    cursor: "pointer",
                    border: "1px solid #faa81d",
                  }}
                >
                  <AddCircleOutlineIcon
                    style={{
                      marginRight: "10px",
                    }}
                  />{" "}
                  Add Car
                </Button>
                <Button>
                  <NotificationsNoneIcon />
                </Button>
              </Box>
            </Box>
          </Box>

          <Box sx={webStyle.customBox3}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

                marginRight: "48px",
                marginLeft: "48px",
                marginBottom: "24px",
                marginTop: "24px",
              }}
            >
              <Box>
                <Box style={styles.tabContainer}>
                  <ul style={{ ...styles.tabNav, textDecoration: "none" }}>
                    <li
                      data-test-id="tab1"
                      style={styles.tabNavItem(this.state.activeTab === "tab1")}
                      onClick={() => this.handleTabClick("tab1")}
                    >
                      All cars (50)
                    </li>
                    <li
                      data-test-id="tab2"
                      style={styles.tabNavItem(this.state.activeTab === "tab2")}
                      onClick={() => this.handleTabClick("tab2")}
                    >
                      Available (10)
                    </li>
                    <li
                      data-test-id="tab3"
                      style={styles.tabNavItem(this.state.activeTab === "tab3")}
                      onClick={() => this.handleTabClick("tab3")}
                    >
                      Rented (12)
                    </li>
                    <li
                      data-test-id="tab4"
                      style={styles.tabNavItem(this.state.activeTab === "tab4")}
                      onClick={() => this.handleTabClick("tab4")}
                    >
                      Reserved (8)
                    </li>
                    <li
                      data-test-id="tab5"
                      style={styles.tabNavItem(this.state.activeTab === "tab5")}
                      onClick={() => this.handleTabClick("tab5")}
                    >
                      Maintenance (0)
                    </li>
                    <li
                      data-test-id="tab6"
                      style={styles.tabNavItem(this.state.activeTab === "tab6")}
                      onClick={() => this.handleTabClick("tab6")}
                    >
                      Out of service (0)
                    </li>
                  </ul>
                  <Box style={styles.tabContent}>
                    {this.state.activeTab === "tab1" && (
                      <Box>Content for Tab 1</Box>
                    )}
                    {this.state.activeTab === "tab2" && (
                      <Box>Content for Tab 2</Box>
                    )}
                    {this.state.activeTab === "tab3" && (
                      <Box>Content for Tab 3</Box>
                    )}
                    {this.state.activeTab === "tab4" && (
                      <Box>Content for Tab 4</Box>
                    )}
                    {this.state.activeTab === "tab5" && (
                      <Box>Content for Tab 5</Box>
                    )}
                    {this.state.activeTab === "tab6" && (
                      <Box>Content for Tab 6</Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box>
                <Button
                  style={{
                    width: "173px",
                    height: "44px",
                    color: "#CBD5E1",
                    padding: "10px 8px 10px 16px",
                    border: "1px solid #CBD5E1",
                    borderRadius: "8px",
                    marginRight: "10px",
                  }}
                >
                  <Typography
                    style={{
                      color: "black",
                    }}
                  >
                    20 March 2024
                  </Typography>
                  <TodayIcon />
                </Button>
                <Button
                  style={{
                    width: "173px",
                    height: "44px",
                    color: "black",
                    padding: "10px 8px 10px 16px",
                    border: "1px solid #CBD5E1",
                    borderRadius: "8px",
                    marginRight: "10px",
                  }}
                >
                  categrory
                </Button>

                <Button
                  style={{
                    backgroundColor: "transparent",
                  }}
                  onClick={this.handleButtonClick}
                >
                  <img src={filterimg} />
                </Button>
                {this.state.showBox && (
                  <Box
                    style={{
                      backgroundColor: "white",
                      width: "400px",
                      height: "808px",
                      position: "absolute",
                      top: "32px",
                      right: 0,
                      zIndex: 1,
                    }}
                  >
                    <Box
                      style={{
                        width: "400px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        maxHeight: "100vh",
                        overflowY: "scroll",
                        borderBottomLeftRadius: "50px",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      }}
                    >
                      <Box
                        style={{
                          ...styles1.flexSpaceBetween,
                          ...styles1.header,
                        }}
                      >
                        <Typography  style={{ marginBottom: "20px" }}>Filters</Typography>
                        <Button
                          style={styles1.buttonTransparent}
                          onClick={this.handleButtonClick}
                        >
                        <CloseIcon style={{
                          color:'black',
                        }}/>
                        </Button>
                      </Box>

                      <Box
                        style={{
                          ...styles1.flexSpaceBetween,
                          ...styles1.sectionPadding,
                          marginBottom: "10px",
                        }}
                      >
                        <Typography style={{ cursor: "pointer" }}>
                          Status
                        </Typography>
                        <Button style={styles1.buttonTransparent}>
                          Select all
                        </Button>
                      </Box>

                      <Box
                        style={{
                          ...styles1.sectionPadding,
                          flexDirection: "column",
                          gap: "16px",
                        }}
                      >
                        <Box style={styles1.labelContainer}>
                          <label htmlFor="available">Available</label>
                          <input
                            type="checkbox"
                            name="available"
                            style={styles1.input}
                          />
                        </Box>
                        <Box style={styles1.labelContainer}>
                          <label htmlFor="rented">Rented</label>
                          <input
                            style={styles1.input}
                            type="checkbox"
                            name="rented"
                          />
                        </Box>
                        <Box style={styles1.labelContainer}>
                          <label htmlFor="reserved">Reserved</label>
                          <input
                            type="checkbox"
                            style={styles1.input}
                            name="reserved"
                          />
                        </Box>
                        <Button style={styles1.buttonTransparent}>
                          Show all
                        </Button>
                      </Box>

                      <Box
                        style={{
                          ...styles1.flexSpaceBetween,
                          ...styles1.sectionPadding,
                          marginBottom: "10px",
                        }}
                      >
                        <Typography style={{ cursor: "pointer" }}>
                          Make
                        </Typography>
                        <Button style={styles1.buttonTransparent}>
                          Select all
                        </Button>
                      </Box>

                      <Box style={styles1.sectionPadding}>
                        <Box style={styles1.labelContainer}>
                          <label htmlFor="volkswagen">Volkswagen</label>
                          <input
                            type="checkbox"
                            style={styles1.input}
                            name="volkswagen"
                          />
                        </Box>
                        <Box style={styles1.labelContainer}>
                          <label htmlFor="volvo">Volvo</label>
                          <input
                            type="checkbox"
                            name="volvo"
                            style={styles1.input}
                          />
                        </Box>
                        <Box style={styles1.labelContainer}>
                          <label htmlFor="bmw">BMW</label>
                          <input
                            style={styles1.input}
                            type="checkbox"
                            name="bmw"
                          />
                        </Box>
                        <Box style={styles1.labelContainer}>
                          <label htmlFor="mercedesBenz">Mercedes-Benz</label>
                          <input
                            type="checkbox"
                            name="mercedesBenz"
                            style={styles1.input}
                          />
                        </Box>
                        <Button style={styles1.buttonTransparent}>
                          Show all
                        </Button>
                      </Box>

                      <Box
                        style={{
                          ...styles1.flexSpaceBetween,
                          ...styles1.sectionPadding,
                          marginBottom: "10px",
                        }}
                      >
                        <Typography style={{ cursor: "pointer" }}>
                          Vehicle Type
                        </Typography>
                        <Button style={styles1.buttonTransparent}>
                          Select all
                        </Button>
                      </Box>

                      <Box style={styles1.sectionPadding}>
                        <Box style={styles1.labelContainer}>
                          <label htmlFor="sedan">Sedan</label>
                          <input
                            type="checkbox"
                            style={styles1.input}
                            name="sedan"
                          />
                        </Box>
                        <Box style={styles1.labelContainer}>
                          <label htmlFor="suv">SUV</label>
                          <input
                            type="checkbox"
                            name="suv"
                            style={styles1.input}
                          />
                        </Box>
                        <Box style={styles1.labelContainer}>
                          <label htmlFor="camper">Camper</label>
                          <input
                            style={styles1.input}
                            type="checkbox"
                            name="camper"
                          />
                        </Box>
                        <Button style={styles1.buttonTransparent}>
                          Show all
                        </Button>
                      </Box>

                      <Box
                        style={{
                          ...styles1.flexSpaceBetween,
                          ...styles1.sectionPadding,
                          marginBottom: "10px",
                        }}
                      >
                        <Typography>Price range</Typography>
                      </Box>
                      <Box
                        style={{
                          ...styles1.flexSpaceBetween,
                          ...styles1.sectionPadding,
                        }}
                      >
                        <Box>
                          <label htmlFor="minimum">Minimum</label>
                          <input
                            type="number"
                            id="minimum"
                            style={{
                              width: "90px",
                              padding: "5px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              height: "25px",
                            }}
                          />
                        </Box>
                        <Box>
                          <label htmlFor="maximum">Maximum</label>
                          <input
                            type="number"
                            id="maximum"
                            style={{
                              width: "90px",
                              padding: "5px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              height: "25px",
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        style={{
                          ...styles1.flexSpaceBetween,
                          borderTop: "1px solid #CBD5E1",
                          padding: "16px 32px",
                        }}
                      >
                        <Button style={styles1.buttonTransparent}>
                          CLEAR ALL
                        </Button>
                        <Button
                          style={{
                            ...styles1.buttonTransparent,
                            backgroundColor: "#ff9900",
                            color: "white",
                            padding: "10px 30px",
                          }}
                        >
                          Apply
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <div>
        {carData.map((car, index) => (
          <Box key={index} style={webStyle.container}>
            <Typography>{car.type} ({index + 1})</Typography>
            <Box style={webStyle.cardBox}>
              <Box style={webStyle.imageBox}>
                <input
                  id={`vehicle${index}`}
                  defaultValue={car.name}
                  type="checkbox"
                  name={`vehicle${index}`}
                />
                <img
                  style={{
                    width: 56,
                    height: 56,
                    borderRadius: 10,
                    objectFit: 'cover',
                  }}
                  src={String(car.image)}
                  alt="car image"
                />
                <Box style={webStyle.textLine}>
                  <Typography style={{ margin: 0 }}>{car.name}</Typography>
                  <Typography style={{ margin: 0, fontSize: 14 }}>
                    Car number: <span style={{ fontWeight: 600 }}>{car.number}</span>
                  </Typography>
                </Box>
              </Box>
              <Box style={webStyle.textLine}>
                <Typography style={webStyle.P}>Price range</Typography>
                <Typography style={webStyle.priceRange}>{car.priceRange}</Typography>
              </Box>
              <Box style={webStyle.textLine}>
                <Typography style={webStyle.P}>Category</Typography>
                <Typography style={webStyle.category}>{car.category}</Typography>
              </Box>
              <Box style={webStyle.textLine}>
                <Typography style={webStyle.P}>Car Status</Typography>
                <Typography style={{ color: '#FAA81D', margin: 0 }}>{car.status}</Typography>
              </Box>
              <Box style={webStyle.imageBox}>
                <Button style={webStyle.button}>EDIT</Button>
                <MoreVertIcon />
              </Box>
            </Box>
          </Box>
        ))}
      </div>
          </Box>
        </Box>
      </ThemeProvider>

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles1 = {
  filterContainer: {
    width: "400px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    maxHeight: "100vh",
    overflowY: "scroll",
    borderBottomLeftRadius: "50px",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sectionPadding: {
    padding: "0px 32px",
  },
  header: {
    borderBottom: "1px solid #CBD5E1",
    padding: "24px 32px",
  },
  buttonTransparent: {
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    color: "#FAA81D",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  input: {
    marginRight: "10px",
  },
};
const webStyle = {
  customBox: {
    justifyContent: "flex-end",
    gap: "13px",
    display: "flex",
    flexDirection: "row",
  },
  container: {
    margin: "0 50px",
  },
  cardBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    border: "1px solid #f1f1f1",
    padding: "16px",
    borderRadius: 7,
    marginBottom: 10,
  },
  P:{
    margin: 0,
    fontSize: "14px",

  },
  imageBox: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  textLine: {
    lineHeight: "22px",
  },
  priceRange: {
    fontSize: "14px",
    margin: 0,
    color: "#059669",
  },
  category: {
    fontSize: "14px",
    margin: 0,
    color: "#FAA81D",
  },
  button: {
    backgroundColor: "transparent",
    border: "1px solid #FAA81D",
    postion: "relative",
    color: "#FAA81D",
    padding: 10,
    borderRadius: 8,
  },
  svgIcon: {
    width: "20px",
    fill: "#000",
  },

  customBox2: {
    width: "100%",
    alignSelf: "center",
  },

  customBox3: {
    width: "100%",
    backgroundImage: `url(${background})`,
  },

  customBox4: {
    display: "flex",
    width: "100%",
  },

  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  buttonStyle: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "360px",
    height: "56px",
    color: "white",
    fontWeight: "700",
  },
  buttonStyleModal: {
    borderRadius: "8px",
    background: "rgba(250, 168, 29, 1)",
    fontSize: "16px",
    width: "120px",
    height: "56px",
    color: "white",
    fontWeight: "700",
  },
  buttonStyleModal2: {
    borderRadius: "8px",
    fontSize: "16px",
    width: "120px",
    height: "56px",
    color: "rgba(250, 168, 29, 1)",
    fontWeight: "700",
  },
  inputStyle: {
    width: "100%",
    height: "56px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid rgba(203, 213, 225, 1)",
  },
  inputStyle2: {
    width: "100%",
    height: "56px",
    padding: "10px 16px 10px 16px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid rgba(203, 213, 225, 1)",
  },
  inputBoxStyle: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  inputBoxStyle2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "12px",
  },
  textStyle: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#475569",
    lineHeight: "22px",
  },
  mainLabel: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    height: 150,
  },

  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "League Spartan",
    fontSize: "24px",
    fontWeight: "700",
    padding: "20px",
    width: "0%",
  },
  mains: {
    BackgroundImage: background,
  },
  Boxide: {
    height: "2px",
    "&::before, &::after": {
      width: "30%",
    },
    "&::before": {
      borderColor: "transparent",
    },
    "&::after": {
      borderColor: "red",
    },
  },
};

// Customizable Area End
