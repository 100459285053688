import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";

// Customizable Area End

import PromptReviewController, { Props } from "./PromptReviewController";

export default class PromptReview extends PromptReviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const {
      llmModel,
      prefix,
      question,
      suffix,
      answer,
      errorField,
      selectErrorField,
      review,
      comment,
      scoring,
      loading,
    } = this.state;
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <Box
        style={{
          width: "100%",
          background: "#eeeeee",
        }}
      >
        <style>
          {`
            
            .MuiSelect-select:focus {
              background-color: transparent !important;
            }
            
          `}
        </style>

        <Box style={{ padding: "30px" }}>
          <Box p={4} style={{ background: "#ffffff", borderRadius: "5px" }}>
            <Box style={{ width: "800px", margin: "auto" }}>
              <FormControl
                fullWidth
                margin="normal"
                style={{ marginBottom: 16 }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "30px",
                  }}
                >
                  <Typography
                    style={{
                      minWidth: 120,
                      color: " rgb(110, 110, 110)",
                      textAlign: "end",
                    }}
                  >
                    LLM Model*
                  </Typography>
                  <Select
                    variant="outlined"
                    value={llmModel}
                    defaultValue="ddddd"
                    style={{ width: "700px", height: "30px" }}
                    error={selectErrorField}
                    disabled
                  >
                    <MenuItem value="" disabled>
                      Select LLM Version
                    </MenuItem>
                    <MenuItem value="gpt-4o-mini">gpt-4o-mini</MenuItem>
                    <MenuItem value="gpt-3.5-turbo-16k">
                      gpt-3.5-turbo-16k
                    </MenuItem>
                    <MenuItem value="gpt-4">gpt-4</MenuItem>
                    <MenuItem value="gpt-4o">gpt-4o</MenuItem>
                  </Select>
                </Box>
                {selectErrorField && (
                  <FormHelperText style={{ color: "#f44336" }}>
                    This field is required
                  </FormHelperText>
                )}
              </FormControl>

              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "30px",
                }}
              >
                <Typography
                  style={{
                    minWidth: 120,
                    color: " rgb(110, 110, 110)",
                    textAlign: "end",
                  }}
                >
                  Prefix
                </Typography>
                <TextField
                  fullWidth
                  name="prfix"
                  variant="outlined"
                  value={prefix}
                  margin="normal"
                  style={{ marginBottom: 16, width: "700px", height: "40px" }}
                  disabled
                />
              </Box>

              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "30px",
                }}
              >
                <Typography
                  style={{
                    minWidth: 120,
                    color: " rgb(110, 110, 110)",
                    textAlign: "end",
                  }}
                >
                  Question*
                </Typography>

                <TextField
                  variant="outlined"
                  margin="normal"
                  name="question"
                  fullWidth
                  error={errorField}
                  required
                  value={question}
                  disabled
                  style={{ marginBottom: "16px" }}
                  helperText={errorField ? "This field is required" : ""}
                />
              </Box>

              <Box
                style={{
                  display: "flex",

                  justifyContent: "space-between",
                  gap: "30px",
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              >
                <Typography
                  style={{
                    minWidth: 120,
                    color: " rgb(110, 110, 110)",
                    textAlign: "end",
                  }}
                >
                  Suffix
                </Typography>
                <textarea
                  placeholder="Please provide a comprehensive response based on the context given. Ensure clarity, thoroughness, and include any relevant examples or details to support your answer."
                  style={{
                    marginBottom: 16,
                    width: "700px",
                    border: "1px solid #bdbdbd",
                    height: "200px",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                  name="suffix"
                  value={suffix}
                  disabled
                />
              </Box>
              <Box
                style={{
                  display: "flex",

                  justifyContent: "space-between",
                  gap: "30px",
                  marginBottom: "16px",
                  marginTop: "16px",
                }}
              >
                <Typography
                  style={{
                    minWidth: 120,
                    color: " rgb(110, 110, 110)",
                    textAlign: "end",
                  }}
                >
                  Answer
                </Typography>
                <textarea
                  style={{
                    marginBottom: 16,
                    width: "700px",
                    border: "1px solid #bdbdbd",
                    height: "200px",
                    padding: "5px",
                    borderRadius: "5px",
                  }}
                  name="suffix"
                  value={answer}
                  disabled
                />
              </Box>

              <Box>
                <Typography variant="h5" gutterBottom>
                  Review
                </Typography>

                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "30px",
                    marginBottom: "16px",
                  }}
                >
                  <Typography
                    style={{
                      minWidth: 120,
                      color: " rgb(110, 110, 110)",
                      textAlign: "end",
                    }}
                  >
                    Review*
                  </Typography>
                  <Select
                    variant="outlined"
                    data-test-id="textInput"
                    value={review}
                    defaultValue="ddddd"
                    style={{ width: "700px", height: "30px" }}
                    error={selectErrorField}
                  >
                    <MenuItem value="like">Like</MenuItem>
                    <MenuItem value="unlike">Dislike</MenuItem>
                  </Select>
                </Box>

                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "30px",
                    marginBottom: "16px",
                  }}
                >
                  <Typography
                    style={{
                      minWidth: 120,
                      color: " rgb(110, 110, 110)",
                      textAlign: "end",
                    }}
                  >
                    comment
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Comment"
                    name="comment"
                    value={comment}
                    data-test-id="textInput2"
                    onChange={this.handleCommentChange}
                  />
                </Box>

                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "30px",
                    marginBottom: "16px",
                  }}
                >
                  <Typography
                    style={{
                      minWidth: 120,
                      color: " rgb(110, 110, 110)",
                      textAlign: "end",
                    }}
                  >
                    Scoring*
                  </Typography>
                  <Select
                    name="scoring"
                    variant="outlined"
                    data-test-id="textInput3"
                    style={{ width: "700px", height: "30px" }}
                    value={scoring}
                    onChange={this.handleSelectChange}
                    error={errorField}
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                  </Select>
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: 8,
                  marginLeft: "150px",
                }}
              >
                <Button
                  onClick={this.handleSubmit}
                  disabled={loading}
                  data-test-id="reviewbtn"
                  style={{
                    backgroundColor: loading ? "#4bacfea1" : "#4bacfe",
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "4px",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading ? (
                    <>
                      <CircularProgress
                        size={20}
                        style={{ color: "white", marginRight: "10px" }}
                      />
                      Updating...
                    </>
                  ) : (
                    "Update Prompt Manager"
                  )}
                </Button>

                <Button
                  style={{
                    color: "#2698f7",
                    backgroundColor: "transparent",
                    textTransform: "capitalize",
                    fontWeight: 600,
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

// Customizable Area End
