import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  getStorageData,
  removeStorageData,
  setStorageData,
} from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showPreview: boolean;
  llmModel: string;
  prefix: string;
  question: string;
  suffix: string;
  error: string;
  errorField: boolean;
  selectErrorField: boolean;
  promptId: string;
  postResponse: any;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PromptManagerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendPromptData: string = "";
  postCheck: any = {};
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showPreview: false,
      llmModel: "",
      prefix: "",
      question: "",
      suffix: "",
      error: "",
      errorField: false,
      selectErrorField: false,
      promptId: "",
      postResponse: {},
      loading: false,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.sendPrompt = this.sendPrompt.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false });

      if (apiRequestCallId === this.sendPromptData) {
        setStorageData("PromptId", responseJson.data.id);
        setStorageData("PromptAnswer", responseJson.data.attributes.answer);

        this.setState({ postResponse: responseJson.data });

        this.props.navigation.navigate("PromptReview");
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    removeStorageData("prefix");
    removeStorageData("question");
    removeStorageData("suffix");
    removeStorageData("llmModel");
    removeStorageData("PromptId");
    removeStorageData("PromptAnswer");
  }

  handleDropdown = (event: any) => {
    this.setState({ llmModel: event.target.value });
    setStorageData("llmModel", event.target.value);
  };

  handleValidation = () => {
    return new Promise<void>((resolve) => {
      const { question, llmModel } = this.state;

      let errorField = false;
      let selectErrorField = false;

      if (llmModel.trim() === "") {
        selectErrorField = true;
      }

      if (question.trim() === "") {
        errorField = true;
      }

      this.setState(
        {
          errorField,
          selectErrorField,
          error: errorField ? "This field is required" : "",
        },
        resolve
      );
    });
  };

  handleSubmit = async () => {
    await this.handleValidation();

    const { errorField, selectErrorField } = this.state;

    if (!errorField && !selectErrorField) {
      this.sendPrompt();
    }
  };

  handlePrefix = (event: any) => {
    this.setState({ prefix: event.target.value });
    setStorageData("prefix", event.target.value);
  };
  handleQuestion = (event: any) => {
    this.setState({ question: event.target.value, error: "" });
    setStorageData("question", event.target.value);
  };
  handleSuffix = (event: any) => {
    this.setState({ suffix: event.target.value });
    setStorageData("suffix", event.target.value);
  };
  handlePreviewClick = () => {
    this.setState({ showPreview: true });
  };

  handleNavigatePreview = () => {
    const { postResponse, errorField } = this.state;

    if (errorField) {
      return;
    }
    this.props.navigation.navigate("PromptPreview");
  };

  handlePreviewSubmit = async () => {
    await this.handleValidation();

    const { errorField, selectErrorField } = this.state;

    if (!errorField && !selectErrorField) {
      setStorageData("llmModel", this.state.llmModel);
      setStorageData("prefix", this.state.prefix);
      setStorageData("question", this.state.question);
      setStorageData("suffix", this.state.suffix);

      this.handleNavigatePreview();
      this.sendPrompt();
    }
  };

  sendPrompt = async () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const data = {
      prompt_manager: {
        gpt_version: this.state.llmModel,
        prefix: this.state.prefix,
        question: this.state.question,
        prompt_template: this.state.suffix,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendPromptData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_prompt_manager/prompt_managers`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
