import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showPreview: boolean;
  llmModel: string;
  prefix: string;
  question: string;
  suffix: string;
  error: string;
  errorField: boolean;
  selectErrorField: boolean;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PromptPreviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendPromptData: string = "";
  postCheck: any = {};
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showPreview: false,
      llmModel: "",
      prefix: "",
      question: "",
      suffix: "",
      error: "",
      errorField: false,
      selectErrorField: false,
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.sendPrompt = this.sendPrompt.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false });

      if (apiRequestCallId === this.sendPromptData) {
        setStorageData("PromptId", responseJson.data.id);
        setStorageData("PromptAnswer", responseJson.data.attributes.answer);

        this.props.navigation.navigate("PromptReview");
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setPreviewStates();
  }

  async setPreviewStates() {
    const question = await getStorageData("question");
    const llmModel = await getStorageData("llmModel");
    const suffix = await getStorageData("suffix");
    const prefix = await getStorageData("prefix");

    this.setState(
      {
        showPreview: !!(llmModel || prefix || suffix || question),
        llmModel: llmModel || "",
        prefix: prefix || "",
        question: question || "",
        suffix: suffix || "",
      },
      () => {}
    );
  }

  handleValidation = () => {
    const { question } = this.state;

    if (question.trim() === "") {
      this.setState({
        error: "This field is required",
        errorField: true,
      });
    } else {
      this.setState({
        errorField: false,
      });
    }
  };

  handleSubmit = () => {
    this.handleValidation();
    this.sendPrompt();
  };
  handlePrefix = (event: any) => {
    this.setState({ prefix: event?.target?.value });
  };
  handleQuestion = (event: any) => {
    this.setState({ question: event?.target?.value, error: "" });
  };
  handleSuffix = (event: any) => {
    this.setState({ suffix: event?.target?.value });
  };


  sendPrompt = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token"),
    };
    const data = {
      prompt_manager: {
        gpt_version: this.state.llmModel,
        prefix: this.state.prefix,
        question: this.state.question,
        prompt_template: this.state.suffix,
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendPromptData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_prompt_manager/prompt_managers`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
