import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@material-ui/core";

// Customizable Area End

import PromptPreviewController, { Props } from "./PromptPreviewController";

export default class PromptPreview extends PromptPreviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { prefix, question, suffix, errorField, loading } = this.state;
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          bgcolor="#ffffff"
        >
          <Box
            width="100%"
            maxWidth="600px"
            boxShadow={3}
            borderRadius={8}
            p={4}
            style={{ backgroundColor: "#f4f4f4" }}
          >
            <Typography variant="h4" align="center" gutterBottom>
              Preview
            </Typography>

            <Box mb={3}>
              <Typography variant="body1" gutterBottom>
                Prefix:
              </Typography>
              <TextField
                fullWidth
                data-test-id="textInput2"
                onClick={this.handlePrefix}
                variant="outlined"
                defaultValue="check ans"
                style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
                value={prefix}
              />
            </Box>

            <Box mb={3}>
              <Typography variant="body1" gutterBottom>
                Question*:
              </Typography>
              <TextField
                onChange={this.handleQuestion}
                fullWidth
                required
                data-test-id="textInput3"
                variant="outlined"
                defaultValue="name our galaxy"
                helperText={errorField ? "This field is required" : ""}
                value={question}
                error={errorField}
                style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
              />
            </Box>

            <Box mb={4}>
              <Typography variant="body1" gutterBottom>
                Suffix:
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                onChange={this.handleSuffix}
                multiline
                data-test-id="textInput4"
                value={suffix}
                defaultValue="Please provide a comprehensive response based on the context given. Ensure clarity, thoroughness, and include any relevant examples or details to support your answer."
                style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
              />
            </Box>

            <Box display="flex" justifyContent="center">
              <Button
                onClick={this.handleSubmit}
                data-test-id="subition"
                disabled={loading}
                variant="contained"
                color="primary"
                size="large"
                style={{
                  borderRadius: "5px",
                  background: loading ? "#4CAF50aa" : "#4CAF50",
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading ? (
                  <>
                    <CircularProgress
                      size={20}
                      style={{ color: "white", marginRight: "10px" }}
                    />
                    Submitting...
                  </>
                ) : (
                  "Create Prompt"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </>

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

// Customizable Area End
