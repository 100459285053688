import React from "react";
// Customizable Area Start
import { Box, Typography } from "@material-ui/core";

// Customizable Area End
import PromptReportController, { Props } from "./PromptReportController";

export default class PromptReport extends PromptReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { promptResuilt } = this.state;
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <Box
        style={{
          width: "100%",
          background: "#eeeeee",
        }}
      >
        <Box style={{ padding: "30px" }}>
          <Box p={4} style={{ background: "#ffffff", borderRadius: "5px" }}>
            <Box p={3} className="container">
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  color: " rgb(110, 110, 110)",
                  marginBottom: "1.35rem",
                  fontWeight: 600,
                }}
              >
                Prompt Manager Details
              </Typography>

              <Box display="flex" mb={2}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ width: "20%", color: " rgb(110, 110, 110)" }}
                >
                  Llm Model:
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: " rgb(110, 110, 110)", width: "80%" }}
                >
                  {promptResuilt?.attributes?.gpt_version}
                </Typography>
              </Box>

              <Box display="flex" mb={2}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ width: "20%", color: " rgb(110, 110, 110)" }}
                >
                  Prefix:
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: " rgb(110, 110, 110)", width: "80%" }}
                >
                  {promptResuilt?.attributes?.prefix}
                </Typography>
              </Box>

              <Box display="flex" mb={2}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ width: "20%", color: " rgb(110, 110, 110)" }}
                >
                  Question:
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: " rgb(110, 110, 110)", width: "80%" }}
                >
                  {promptResuilt?.attributes?.question}
                </Typography>
              </Box>

              <Box display="flex" mb={2}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ width: "20%", color: " rgb(110, 110, 110)" }}
                >
                  Prompt Template:
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: " rgb(110, 110, 110)", width: "80%" }}
                >
                  {promptResuilt?.attributes?.prompt_template}
                </Typography>
              </Box>

              <Box display="flex" mb={2}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ width: "20%", color: " rgb(110, 110, 110)" }}
                >
                  Answer:
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: " rgb(110, 110, 110)", width: "80%" }}
                >
                  {promptResuilt?.attributes?.answer}
                </Typography>
              </Box>

              <Box display="flex" mb={2}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ width: "20%", color: " rgb(110, 110, 110)" }}
                >
                  Created At:
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: " rgb(110, 110, 110)", width: "80%" }}
                >
                  {this.state.date}
                </Typography>
              </Box>
            </Box>
            <Box p={3} className="container">
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  color: " rgb(110, 110, 110)",
                  marginBottom: "1.35rem",
                  fontWeight: 600,
                }}
              >
                Review
              </Typography>

              <Box display="flex" mb={2}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ width: "20%", color: " rgb(110, 110, 110)" }}
                >
                  review:
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: " rgb(110, 110, 110)", width: "80%" }}
                >
                  {promptResuilt?.attributes?.prompt_review?.review}
                </Typography>
              </Box>

              <Box display="flex" mb={2}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ width: "20%", color: " rgb(110, 110, 110)" }}
                >
                  comment:
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: " rgb(110, 110, 110)", width: "80%" }}
                >
                  {promptResuilt?.attributes?.prompt_review?.comment
                    ? promptResuilt?.attributes?.prompt_review?.comment
                    : "empty"}
                </Typography>
              </Box>

              <Box display="flex" mb={2}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ width: "20%", color: " rgb(110, 110, 110)" }}
                >
                  scoring:
                </Typography>
                <Typography
                  variant="body1"
                  style={{ color: " rgb(110, 110, 110)", width: "80%" }}
                >
                  {promptResuilt?.attributes?.prompt_review?.scoring}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

// Customizable Area End
